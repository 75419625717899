<template>
  <div
    id="j_id_6_3v_2:modalTimeout"
    class="c-modal modalTimeout"
    data-size="small"
    role="dialog"
    data-closeable="false"
    tabindex="-1"
    aria-modal="true"
    data-active="true"
    style="display: block; z-index: 1050; width: 100%"
    v-if="show"
  >
    <div
      class="c-modal__backmask"
      onclick="$('.modalTimeout').modal('hide')"
    ></div>
    <div class="c-modal__wrapper">
      <div class="c-modal__container">
        <div class="c-modal__close" style="">
          <button type="button" class="c-modal__close-btn" data-dismiss="modal">
            <span
              class="o-box--small--horizontal o-box--tiny--vertical u-font-weight--500 u-font-size--small u-border-radius--sm u-line-height--1 u-pointer-events--none"
            >
              Close
              <i class="fa-solid fa-caret-right u-font-size--default"></i></span
            ><i class="u-display--block fa-regular fa-close"></i>
          </button>
        </div>
        <div class="c-modal__body">
          <div
            class="c-modal__header u-font-weight--600 u-line-height--1 u-hide-empty"
          ></div>
          <div
            class="bg-clr--white c-session-expired o-block u-text-align--center"
          >
            <div
              class="c-session-expired__item o-block__item o-block o-block--small"
            >
              <i
                class="o-block__item fa-regular fa-bell u-font-size--huge clr--middle-gray"
              ></i
              ><b
                class="c-session-expired__title o-block__item u-font-size--big u-line-height--1"
                style="text-align: center;"
                >{{ title }}</b
              >
            </div>
            <div
              class="c-session-expired__item o-block__item o-block o-block--small"
              v-if="description"
            >
              <p class="o-block__item u-font-size--small">
                {{ description }}
              </p>
              <!-- <b class="o-block__item u-font-size--small"
                >Click OK to refresh the page.</b
              > -->
            </div>
            <div class="c-session-expired__item o-block__item">
              <a @click="closeAlert" class="c-button bg-clr--brand"> OK </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["show", "title", "description", "closeAlert"],
  methods: {},
};
</script>
