<template>
  <div>
    <nav
      class="navbar header-personalizado"
      role="navigation"
      id="generic-MS-nav"
      v-if="true"
    >
      <div
        class="c-header-collective__bar clr--white"
        :style="`background-color: #${club.ColorSecundarioHex}; color: #${club.ColorSecundarioTextoHex}`"
      >
        <div class="o-container o-container--large">
          <div
            class="o-flexbox o-flexbox--generic-row u-align-items--center u-font-size--small u-justify-content--end"
          >
            <div
              class="c-header__agency-data o-group--default u-display--none u-display--flex@md"
            >
              <div>
                <b>{{ club.Nombre }}</b>
              </div>
              <div class="o-group--tiny" v-if="club.Telefono">
                <i class="fa-regular fa-phone"></i
                ><span>{{ club.Telefono }}</span>
              </div>
              <div class="o-group--tiny" v-if="club.TelefonoWs">
                <i class="fab fa-whatsapp"></i
                ><span>{{ club.TelefonoWs }}</span>
              </div>
              <div class="o-group--tiny u-text-transform--lowercase">
                <i class="fa-regular fa-envelope"></i
                ><a :href="`mailto:${club.Email}`"> {{ club.Email }}</a>
              </div>
              <div class=""></div>
            </div>
          </div>
        </div>
      </div>
      <div class="container o-container o-container--large">
        <div class="navbar-header">
          <button
            class="navbar-toggle collapsed c-button--userheader ml-auto"
            data-target="#collapsableHeader"
            data-toggle="collapse"
            type="button"
          >
            <span class="sr-only"></span><span class="icon-bar top-bar"></span
            ><span class="icon-bar middle-bar"></span
            ><span class="icon-bar bottom-bar"></span></button
          ><a class="ms-brand">
            <img :src="club.Logo" alt="brand" class="img-brand"
          /></a>
        </div>
        <div id="collapsableHeader" class="navbar-collapse collapse">
          <div class="navbar-display">
            <ul class="nav navbar-nav navbar-right flip userheader">
              <li id="dropdown_languages" class="dropdown dropdown-languages">
                <a
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                  @click="changeIso"
                  style="cursor: pointer"
                  ><span
                    ><span
                      :class="`flag-icon flag-icon--language ${
                        iso === 'en' ? 'flag-icon-us' : 'flag-icon-es fa'
                      }`"
                    ></span>
                    {{
                      iso === "es"
                        ? $func.parseIso("spanish")
                        : $func.parseIso("english")
                    }}</span
                  >
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <div class="c-header-collective" v-else>
      <div
        class="c-header-collective__bar clr--white"
        :style="`background-color: #${club.ColorSecundarioHex}; color: #${club.ColorSecundarioTextoHex}`"
      >
        <div class="o-container o-container--large">
          <div
            class="o-flexbox o-flexbox--generic-row u-align-items--center u-font-size--small u-justify-content--end"
          >
            <div
              class="c-header__agency-data o-group--default u-display--none u-display--flex@md"
            >
              <div>
                <b>{{ club.Nombre }}</b>
              </div>
              <div class="o-group--tiny" v-if="club.Telefono">
                <i class="fa-regular fa-phone"></i
                ><span>{{ club.Telefono }}</span>
              </div>
              <div class="o-group--tiny" v-if="club.TelefonoWs">
                <i class="fab fa-whatsapp"></i
                ><span>{{ club.TelefonoWs }}</span>
              </div>
              <div class="o-group--tiny u-text-transform--lowercase">
                <i class="fa-regular fa-envelope"></i
                ><a :href="`mailto:${club.Email}`"> {{ club.Email }}</a>
              </div>
              <div class=""></div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-header-collective__main bg-clr--white">
        <div class="o-container o-container--large pr-0 pr-20@md">
          <div
            class="c-header-collective__display o-flexbox o-flexbox--generic-row"
          >
            <div
              id="j_id_c_6_1n:collective-img__container"
              class="c-collective-brand c-collective-brand--header o-section--small"
            >
              <a class="c-collective-brand__link">
                <img class="c-collective-brand__img" :src="club.Logo"
              /></a>
            </div>
            <div
              class="c-button c-button--square u-display--none@md u-border--none"
              onclick="openHiddenAside('header-collective')"
            >
              <i class="fa-regular fa-bars"></i>
            </div>
            <div
              id="c-hidden-aside--header-collective"
              class="c-hidden-aside c-hidden-aside@md modifier"
            >
              <div class="c-hidden-aside__scroller">
                <div class="u-display--none@md u-text-align--right">
                  <a
                    href="#"
                    class="c-button c-button--square u-bdr-clr--transparent"
                    onclick="closeHiddenAside('header-collective')"
                    ><i class="fa-regular fa-times"></i
                  ></a>
                </div>
                <div
                  class="c-header__agency-data o-block o-block--small o-box bg-clr--lighter-gray u-display--none@md mt-20"
                >
                  <div
                    class="o-block__item u-display--flex u-align-items--center c-header-collective__aside-item"
                  >
                    <b>Ascendant Holidays</b>
                  </div>
                  <div
                    class="o-group--tiny o-block__item u-display--flex u-align-items--center c-header-collective__aside-item"
                  >
                    <i class="fa-regular fa-phone"></i><span>+18772123834</span>
                  </div>
                  <div
                    class="o-group--tiny o-block__item u-display--flex u-align-items--center c-header-collective__aside-item"
                  >
                    <i class="fab fa-whatsapp"></i><span>+529982666984</span>
                  </div>
                  <div
                    class="o-group--tiny u-text-transform--lowercase o-block__item u-display--flex u-align-items--center c-header-collective__aside-item"
                  >
                    <i class="fa-regular fa-envelope"></i
                    ><a href="mailto:reservations@vacancyrewards.com">
                      reservations@vacancyrewards.com</a
                    >
                  </div>
                  <div
                    class="o-block__item u-display--flex u-align-items--center c-header-collective__aside-item"
                  ></div>
                </div>
                <ul class="c-header-collective__user-header u-display--flex@md">
                  <li class="u-display--none"></li>
                  <li class="btn-menu-help" style="visibility: hidden">
                    <a
                      href="#"
                      class="tc-bt-showhelp fakeLink"
                      onclick="if($('#c-hidden-aside--header-collective')){ closeHiddenAside('header-collective') } $('#collapsableHeader').collapse('hide');"
                      style="display: none"
                    >
                      Ayúdame
                    </a>
                  </li>
                  <li
                    id="dropdown_languages"
                    class="dropdown dropdown-languages"
                    data-selected-language="es"
                  >
                    <a
                      class="dropdown-toggle"
                      data-toggle="dropdown"
                      @click="changeIso"
                      style="cursor: pointer"
                      ><span
                        ><span
                          :class="`flag-icon flag-icon--language ${
                            iso === 'en' ? 'flag-icon-us' : 'flag-icon-es fa'
                          }`"
                        ></span>
                        {{
                          iso === "es"
                            ? $func.parseIso("spanish")
                            : $func.parseIso("english")
                        }}</span
                      >
                      <i class="fa-regular fa-angle-down"></i>
                    </a>
                    <!-- <ul class="dropdown-menu attach-body" role="menu">
                            <li>
                              <a
                                id="btChangeLanguage"
                                class="language-en u-cursor--pointer"
                                title="English"
                                onclick="changeLanguage([{name: 'language', value: 'EN'}])"
                                ><span
                                  ><span
                                    class="flag-icon flag-icon--language flag-icon-gb fa"
                                  ></span>
                                  English</span
                                ></a
                              >
                            </li>
                          </ul> -->
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            id="c-hidden-aside--submenu__bg"
            class="c-hidden-aside__bg"
            onclick="closeHiddenAside('submenu')"
          ></div>
          <div
            id="c-hidden-aside--header-collective__bg"
            class="c-hidden-aside__bg c-hidden-aside__bg@md"
            onclick="closeHiddenAside('header-collective')"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iso: 'en',
    };
  },
  props: ["isDefault", "club"],
  async created() {
    const iso = localStorage.getItem("iso");
    this.iso = iso || "en";
  },
  methods: {
    changeIso() {
      if (this.iso === "es") {
        localStorage.setItem("iso", "en");
        this.iso = "en";
      } else {
        localStorage.setItem("iso", "es");
        this.iso = "es";
      }
      this.$bus.emit("change-iso");
    },
  },
};
</script>
