<template>
  <div>
    <Header />
    <form id="form" name="form">
      <span
        id="j_id_33"
        class="ui-growl-pl"
        data-widget="growl"
        data-summary="data-summary"
        data-detail="data-detail"
        data-severity="all,error"
        data-redisplay="true"
      ></span>
      <div
        class="c-hero-screen"
        data-model="default"
        data-size="fixed"
        data-tabs="horizontal"
      >
        <div class="c-hero-screen__wrapper">
          <div class="c-hero-screen__media">
            <div class="c-hero-screen__image">
              <picture
                ><source
                  media="(min-width: 1200px)"
                  :data-srcset="setImg"
                  :srcset="setImg" />
                <source
                  media="(min-width: 768px)"
                  :data-srcset="setImg"
                  :srcset="setImg" />
                <img
                  :data-src="setImg"
                  :src="setImg"
                  data-error-src="https://booking.kovayvacations.com.mx/javax.faces.resource/images/no-photo-XS.jpg"
                  alt=""
                  crossorigin="anonymous"
                  class="lazy-back-home-background-image u-image-object--cover u-image-object--full lazy-img loaded img-jdaza"
                  loading="lazy"
                  data-ll-status="loaded"
              /></picture>
            </div>
            <div class="c-hero-screen__header u-zindex--4">
              <div class="c-hero-screen__header__wrapper">
                <h1
                  class="m-0 u-font-size--bigger u-font-size--giant@sm u-font-size--jumbo@xl u-font-size--extra-jumbo@xxl u-font-weight--700"
                ></h1>
                <h2
                  class="m-0 u-font-size--middle u-font-size--big@sm u-font-size--bigger@xl u-font-weight--700 pt-10"
                ></h2>
              </div>
            </div>
          </div>
          <div
            id="j_id_4c:dev-searchbox"
            class="c-hero-screen__engine dev-searchbox"
          >
            <div
              id="form-content-wrapper"
              class="sb-main form-content-wrapper"
              style=""
            >
              <div class="sb-main__box">
                <div class="box-welcome2">
                  <div class="box-home-search pb-10">
                    <span class="sb-tabs__backmask">
                      <div class="sb-tabs__link">
                        <i class="sb-tabs__icon fa-light fa-route"></i
                        ><span class="sb-tabs__text">hidden text</span>
                      </div></span
                    >
                    <div
                      id="j_id_54:init-compositor-all:nav-tabs"
                      class="sb-tabs"
                    >
                      <div class="sb-tabs__wrapper">
                        <div class="sb-tabs__container loaded">
                          <div
                            data-type="HOLIDAYS"
                            :class="`sb-tabs__item item search search-holidays call-pp-help ${
                              tab === 1 ? 'sb-tabs__item--active' : ''
                            }`"
                            data-placement="top"
                            data-content="Componga su viaje"
                          >
                            <a
                              id="j_id_54:init-compositor-all:homeTab:1:holidayPackage"
                              class="ui-commandlink ui-widget sb-tabs__link"
                              aria-label=""
                              aria-expanded="false"
                              style="cursor: pointer"
                              @click="selectTab(1)"
                            >
                              <i class="sb-tabs__icon fa-light fa-suitcase"></i>
                              <span class="sb-tabs__text">
                                {{ $func.parseIso("weeks") }}</span
                              ></a
                            >
                          </div>
                          <div
                            data-type="ONLY_HOTEL"
                            class="sb-tabs__item item search"
                            :class="`sb-tabs__item item search ${
                              tab === 2 ? 'sb-tabs__item--active' : ''
                            }`"
                            data-placement="top"
                            data-content="Componga su viaje"
                            v-if="ModuloCruceros"
                          >
                            <a
                              id="j_id_54:init-compositor-all:homeTab:1:holidayPackage"
                              class="ui-commandlink ui-widget sb-tabs__link"
                              aria-label=""
                              aria-expanded="false"
                              style="cursor: pointer"
                              @click="selectTab(2)"
                            >
                              <i class="sb-tabs__icon fa-light fa-ship"></i>
                              <span class="sb-tabs__text">
                                {{ $func.parseIso("cruises") }}</span
                              ></a
                            >
                          </div>
                          <div
                            data-type="ONLY_HOTEL"
                            class="sb-tabs__item item search"
                            :class="`sb-tabs__item item search ${
                              tab === 3 ? 'sb-tabs__item--active' : ''
                            }`"
                            data-placement="top"
                            data-content="Componga su viaje"
                            v-if="ModuloMyDeals && KeyMyDeals && UrlMyDeals"
                          >
                            <a
                              id="j_id_54:init-compositor-all:homeTab:1:holidayPackage"
                              class="ui-commandlink ui-widget sb-tabs__link"
                              aria-label=""
                              aria-expanded="false"
                              style="cursor: pointer"
                              @click="goMyDeals()"
                            >
                              <img
                                src="https://vacancyelite.com/images/layer2.png"
                                alt="mydeals"
                                style="width: 50px"
                              />
                            </a>
                          </div>
                          <div
                            data-type="ONLY_FLIGHT"
                            class="sb-tabs__item item search"
                            data-placement="top"
                            data-content="Componga su viaje"
                            style="visibility: hidden"
                          >
                            <a
                              id="j_id_54:init-compositor-all:homeTab:2:onlyFlight"
                              href="#"
                              class="ui-commandlink ui-widget sb-tabs__link dev-tab-onlyFlight"
                              aria-label=""
                              onclick='PrimeFaces.ab({s:"j_id_54:init-compositor-all:homeTab:2:onlyFlight",f:"form",p:"j_id_54:init-compositor-all:homeTab:2:onlyFlight",u:"j_id_54:init-compositor-all:form-content @(.destinations-photos)",onst:function(cfg){travelc.home.blockForm(true);},onco:function(xhr,status,args,data){travelc.home.blockForm(false); history.replaceState(&apos;&apos;,&apos;&apos;,&apos;?tripType=ONLY_FLIGHT&apos;);;}});return false;'
                              aria-expanded="false"
                            >
                              <i
                                class="sb-tabs__icon fa-light fa-plane-departure"
                              ></i
                              ><span class="sb-tabs__text"> Vuelos</span></a
                            >
                          </div>
                          <div
                            data-type="FLIGHT_HOTEL"
                            class="sb-tabs__item item search"
                            data-placement="top"
                            data-content="Componga su viaje"
                          >
                            <a
                              id="j_id_54:init-compositor-all:homeTab:3:flightHotel"
                              href="#"
                              class="ui-commandlink ui-widget sb-tabs__link dev-tab-flightHotel"
                              aria-label=""
                              onclick='travelc.home.blockForm(true);PrimeFaces.ab({s:"j_id_54:init-compositor-all:homeTab:3:flightHotel",f:"form",p:"j_id_54:init-compositor-all:homeTab:3:flightHotel",u:"j_id_54:init-compositor-all:form-content @(.destinations-photos)",onco:function(xhr,status,args,data){travelc.home.blockForm(false); history.replaceState(&apos;&apos;,&apos;&apos;,&apos;?tripType=FLIGHT_HOTEL&apos;);;}});return false;'
                              aria-expanded="false"
                              style="visibility: hidden"
                            >
                              <div
                                class="sb-tabs__icon sb-tabs__icon--combo o-flexbox u-justify-content--center u-align-items--center"
                              >
                                <i class="fa-light fa-plane-departure"></i
                                ><span
                                  class="o-box--tiny--horizontal u-line-height--1 u-font-size--middle"
                                  >+</span
                                ><i class="fa-light fa-bed-front"></i>
                              </div>
                              <span class="sb-tabs__text">
                                Vuelo + Hotel</span
                              ></a
                            >
                          </div>
                          <div
                            data-type="ONLY_TICKET"
                            class="sb-tabs__item item search"
                            data-placement="top"
                            data-content="Componga su viaje"
                            style="visibility: hidden"
                          >
                            <a
                              id="j_id_54:init-compositor-all:homeTab:4:onlyTicket"
                              href="#"
                              class="ui-commandlink ui-widget sb-tabs__link dev-tab-onlyTicket"
                              aria-label=""
                              onclick='travelc.home.blockForm(true);;PrimeFaces.ab({s:"j_id_54:init-compositor-all:homeTab:4:onlyTicket",f:"form",p:"j_id_54:init-compositor-all:homeTab:4:onlyTicket",u:"j_id_54:init-compositor-all:form-content @(.destinations-photos)",onco:function(xhr,status,args,data){travelc.home.blockForm(false); history.replaceState(&apos;&apos;,&apos;&apos;,&apos;?tripType=ONLY_TICKET&apos;);;}});return false;'
                              aria-expanded="false"
                            >
                              <i class="sb-tabs__icon fa-light fa-ticket"></i
                              ><span class="sb-tabs__text">
                                Actividades</span
                              ></a
                            >
                          </div>
                          <div
                            data-type="ONLY_CAR"
                            class="sb-tabs__item item search"
                            data-placement="top"
                            data-content="Componga su viaje"
                            style="visibility: hidden"
                          >
                            <a
                              id="j_id_54:init-compositor-all:homeTab:5:onlyCar"
                              href="#"
                              class="ui-commandlink ui-widget sb-tabs__link dev-tab-onlycar"
                              aria-label=""
                              onclick='travelc.home.blockForm(true);;PrimeFaces.ab({s:"j_id_54:init-compositor-all:homeTab:5:onlyCar",f:"form",p:"j_id_54:init-compositor-all:homeTab:5:onlyCar",u:"j_id_54:init-compositor-all:form-content @(.destinations-photos)",onco:function(xhr,status,args,data){travelc.home.blockForm(false); history.replaceState(&apos;&apos;,&apos;&apos;,&apos;?tripType=ONLY_CAR&apos;);;}});return false;'
                              aria-expanded="false"
                            >
                              <i class="sb-tabs__icon fa-light fa-car"></i
                              ><span class="sb-tabs__text">
                                Renta de Auto</span
                              ></a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="j_id_54:init-compositor-all:form-content"
                      class="tab-content home-search HOLIDAYS search-form-content"
                      v-if="tab === 1"
                    >
                      <div
                        class="o-layout o-layout--gutter-small o-layout--bottom o-layout--fix u-text-align--left"
                      >
                        <div
                          :class="`o-layout__item u-1/${
                            checkFormState() ? '6' : '5'
                          }@xs pb-20`"
                        >
                          <div class="c-sb-input">
                            <label
                              id="j_id_54:init-compositor-all:j_id_1nm"
                              class="ui-outputlabel ui-widget c-sb-input__label"
                              for="j_id_54:init-compositor-all:country"
                              ><span class="ui-outputlabel-label">{{
                                $func.parseIso("country")
                              }}</span></label
                            >
                            <div
                              class="c-sb-input__addicon c-sb-input__addicon--drop"
                            >
                              <select
                                id="j_id_54:init-compositor-all:country"
                                name="j_id_54:init-compositor-all:country"
                                size="1"
                                class="c-sb-input__field dev-select-country"
                                v-model="country"
                                @change="checkCountry"
                                :disabled="countries.length === 0"
                              >
                                <option :value="null">
                                  {{ $func.parseIso("countrySelected") }}
                                </option>
                                <option
                                  :value="item.Country"
                                  v-for="item in countries"
                                >
                                  {{ item.Country }}
                                </option>
                              </select>
                              <i
                                class="c-sb-input__icon fa-light fa-map-marker-alt"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div
                          :class="`o-layout__item u-1/${
                            checkFormState() ? '6' : '5'
                          }@xs pb-20`"
                          v-if="
                            country &&
                            (country === 'USA' || country === 'Canada')
                          "
                        >
                          <div class="c-sb-input">
                            <label
                              id="j_id_54:init-compositor-all:j_id_1nm"
                              class="ui-outputlabel ui-widget c-sb-input__label"
                              for="j_id_54:init-compositor-all:country"
                              ><span class="ui-outputlabel-label">{{
                                $func.parseIso("state")
                              }}</span></label
                            >
                            <div
                              class="c-sb-input__addicon c-sb-input__addicon--drop"
                            >
                              <select
                                id="j_id_54:init-compositor-all:country"
                                name="j_id_54:init-compositor-all:country"
                                size="1"
                                class="c-sb-input__field dev-select-country"
                                v-model="state"
                                @change="checkState"
                                :disabled="states.length === 0"
                              >
                                <option :value="null">
                                  {{ $func.parseIso("stateSelected") }}
                                </option>
                                <option
                                  :value="item.CODIGO"
                                  v-for="item in states"
                                >
                                  {{ item.NOMBRE_ESTADO }}
                                </option>
                              </select>
                              <i
                                class="c-sb-input__icon fa-light fa-map-marker-alt"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div
                          :class="`o-layout__item u-1/${
                            checkFormState() ? '6' : '5'
                          }@xs pb-20`"
                        >
                          <div class="c-sb-input">
                            <label
                              id="j_id_54:init-compositor-all:j_id_1nm"
                              class="ui-outputlabel ui-widget c-sb-input__label"
                              for="j_id_54:init-compositor-all:country"
                              ><span class="ui-outputlabel-label">{{
                                $func.parseIso("city")
                              }}</span></label
                            >
                            <div
                              class="c-sb-input__addicon c-sb-input__addicon--drop"
                            >
                              <select
                                id="j_id_54:init-compositor-all:country"
                                name="j_id_54:init-compositor-all:country"
                                size="1"
                                class="c-sb-input__field dev-select-country"
                                v-model="city"
                                @change="checkMonths"
                                :disabled="cities.length === 0"
                              >
                                <option :value="null">
                                  {{ $func.parseIso("citySelected") }}
                                </option>
                                <option :value="'all'">
                                  {{ $func.parseIso("allCity") }}
                                </option>
                                <option
                                  :value="item.City"
                                  v-for="item in cities"
                                >
                                  {{ item.City }}
                                </option>
                              </select>
                              <i
                                class="c-sb-input__icon fa-light fa-map-marker-alt"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div
                          :class="`o-layout__item u-1/${
                            checkFormState() ? '6' : '5'
                          }@xs pb-20`"
                        >
                          <div class="c-sb-input">
                            <label
                              id="j_id_54:init-compositor-all:j_id_1nm"
                              class="ui-outputlabel ui-widget c-sb-input__label"
                              for="j_id_54:init-compositor-all:country"
                              ><span class="ui-outputlabel-label">{{
                                $func.parseIso("checkIn")
                              }}</span></label
                            >
                            <div
                              class="c-sb-input__addicon c-sb-input__addicon--drop"
                            >
                              <select
                                id="j_id_54:init-compositor-all:country"
                                name="j_id_54:init-compositor-all:country"
                                size="1"
                                class="c-sb-input__field dev-select-country"
                                v-model="checkIn"
                                :disabled="checkInList.length === 0"
                              >
                                <option :value="null">
                                  {{ $func.parseIso("checkIn") }}
                                </option>
                                <option
                                  :value="item"
                                  v-for="item in checkInList"
                                >
                                  {{ formatDate(item) }}
                                </option>
                              </select>
                              <i
                                class="c-sb-input__icon fa-light fa-map-marker-alt"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div
                          :class="`o-layout__item u-1/${
                            checkFormState() ? '6' : '5'
                          }@xs pb-20`"
                        >
                          <div class="c-sb-input">
                            <label
                              id="j_id_54:init-compositor-all:j_id_1nm"
                              class="ui-outputlabel ui-widget c-sb-input__label"
                              for="j_id_54:init-compositor-all:country"
                              ><span class="ui-outputlabel-label">{{
                                $func.parseIso("checkOut")
                              }}</span></label
                            >
                            <div
                              class="c-sb-input__addicon c-sb-input__addicon--drop"
                            >
                              <select
                                id="j_id_54:init-compositor-all:country"
                                name="j_id_54:init-compositor-all:country"
                                size="1"
                                class="c-sb-input__field dev-select-country"
                                v-model="checkOut"
                                :disabled="!checkIn"
                              >
                                <option :value="null">
                                  {{ $func.parseIso("checkOut") }}
                                </option>
                                <option
                                  :value="item"
                                  v-for="item in checkOutList"
                                >
                                  {{ formatDate(item) }}
                                </option>
                              </select>
                              <i
                                class="c-sb-input__icon fa-light fa-map-marker-alt"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="j_id_54:init-compositor-all:search-button-panel"
                          :class="`o-layout__item u-1/${
                            checkFormState() ? '6' : '5'
                          }@xs u-1/${checkFormState() ? '6' : '5'}@md pb-20`"
                        >
                          <a
                            id="j_id_54:init-compositor-all:search"
                            name="j_id_54:init-compositor-all:search"
                            class="c-sb-input__button c-button continuar bg-clr--brand u-1/1 call-pp-help dev-holidays-search-btn"
                            title="Buscar"
                            data-placement="bottom"
                            data-content="Y busque paquetes"
                            @click="listWeeks"
                            :style="
                              btnColor ? `background-color: #${btnColor};` : ''
                            "
                          >
                            {{
                              textLoading === ""
                                ? $func.parseIso("find")
                                : textLoading
                            }}
                          </a>
                        </div>
                        <!-- <div class="o-layout__item u-1/1 u-text-align--right">
                          <span class="terms-use u-font-size--default"
                            >Haciendo click en "Buscar", acepto las
                            <a
                              id="j_id_54:init-compositor-all:j_id_1ol"
                              href="#"
                              class="ui-commandlink ui-widget"
                              aria-label=""
                              onclick='PrimeFaces.ab({s:"j_id_54:init-compositor-all:j_id_1ol",f:"form",u:"@(.linkTerms)",onco:function(xhr,status,args,data){$(&apos;.linkTerms&apos;).modal(&apos;show&apos;);},pa:[{name:"modalUseTerms",value:"true"}]});return false;'
                            >
                              <span
                                class="c-inline-link c-inline-link--underline"
                                >Condiciones de uso</span
                              ></a
                            >
                          </span>
                        </div> -->
                      </div>
                    </div>
                    <div
                      id="j_id_54:init-compositor-all:form-content"
                      class="tab-content home-search HOLIDAYS search-form-content"
                      v-if="tab === 2"
                    >
                      <div
                        class="o-layout o-layout--gutter-small o-layout--bottom o-layout--fix u-text-align--left"
                      >
                        <div :class="`o-layout__item u-1/5@xs pb-20`">
                          <div class="c-sb-input">
                            <label
                              id="j_id_54:init-compositor-all:j_id_1nm"
                              class="ui-outputlabel ui-widget c-sb-input__label"
                              for="j_id_54:init-compositor-all:country"
                              ><span class="ui-outputlabel-label">{{
                                $func.parseIso("destination")
                              }}</span></label
                            >
                            <div
                              class="c-sb-input__addicon c-sb-input__addicon--drop"
                            >
                              <select
                                id="j_id_54:init-compositor-all:country"
                                name="j_id_54:init-compositor-all:country"
                                size="1"
                                class="c-sb-input__field dev-select-country"
                                v-model="cruise.destination"
                                @change="listCruises(true)"
                              >
                                <option :value="''">
                                  {{ $func.parseIso("allDestinations") }}
                                </option>
                                <option
                                  :value="item[0]"
                                  v-for="item in allDestinations"
                                >
                                  {{ item[2] }}
                                </option>
                              </select>
                              <i
                                class="c-sb-input__icon fa-light fa-map-marker-alt"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div :class="`o-layout__item u-1/5@xs pb-20`">
                          <div class="c-sb-input">
                            <label
                              id="j_id_54:init-compositor-all:j_id_1nm"
                              class="ui-outputlabel ui-widget c-sb-input__label"
                              for="j_id_54:init-compositor-all:country"
                              ><span class="ui-outputlabel-label">{{
                                $func.parseIso("depPort")
                              }}</span></label
                            >
                            <div
                              class="c-sb-input__addicon c-sb-input__addicon--drop"
                            >
                              <select
                                id="j_id_54:init-compositor-all:country"
                                name="j_id_54:init-compositor-all:country"
                                size="1"
                                class="c-sb-input__field dev-select-country"
                                v-model="cruise.port"
                                @change="listCruises(false)"
                              >
                                <option :value="''">
                                  {{ $func.parseIso("allPorts") }}
                                </option>
                                <option
                                  :value="item[0]"
                                  v-for="item in allPorts"
                                >
                                  {{ item[2] }}
                                </option>
                              </select>
                              <i
                                class="c-sb-input__icon fa-light fa-portrait"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div :class="`o-layout__item u-1/5@xs pb-20`">
                          <div class="c-sb-input">
                            <label
                              id="j_id_54:init-compositor-all:j_id_1nm"
                              class="ui-outputlabel ui-widget c-sb-input__label"
                              for="j_id_54:init-compositor-all:country"
                              ><span class="ui-outputlabel-label">{{
                                $func.parseIso("cruiseLine")
                              }}</span></label
                            >
                            <div
                              class="c-sb-input__addicon c-sb-input__addicon--drop"
                            >
                              <select
                                id="j_id_54:init-compositor-all:country"
                                name="j_id_54:init-compositor-all:country"
                                size="1"
                                class="c-sb-input__field dev-select-country"
                                v-model="cruise.cruiseline"
                                @change="listCruises(false)"
                              >
                                <option :value="''">
                                  {{ $func.parseIso("allCruiseLines") }}
                                </option>
                                <option
                                  :value="item[0]"
                                  v-for="item in allCruiseLines"
                                >
                                  {{ item[2] }}
                                </option>
                              </select>
                              <i
                                class="c-sb-input__icon fa-light fa-registered"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div :class="`o-layout__item u-1/5@xs pb-20`">
                          <div class="c-sb-input">
                            <label
                              id="j_id_54:init-compositor-all:j_id_1nm"
                              class="ui-outputlabel ui-widget c-sb-input__label"
                              for="j_id_54:init-compositor-all:country"
                              ><span class="ui-outputlabel-label">{{
                                $func.parseIso("ship")
                              }}</span></label
                            >
                            <div
                              class="c-sb-input__addicon c-sb-input__addicon--drop"
                            >
                              <select
                                id="j_id_54:init-compositor-all:country"
                                name="j_id_54:init-compositor-all:country"
                                size="1"
                                class="c-sb-input__field dev-select-country"
                                v-model="cruise.ship"
                                @change="listCruises(false)"
                              >
                                <option :value="''">
                                  {{ $func.parseIso("allShips") }}
                                </option>
                                <option
                                  :value="item[0]"
                                  v-for="item in allShips"
                                >
                                  {{ item[2] }}
                                </option>
                              </select>
                              <i class="c-sb-input__icon fa-light fa-ship"></i>
                            </div>
                          </div>
                        </div>
                        <div :class="`o-layout__item u-1/5@xs pb-20`">
                          <div class="c-sb-input">
                            <label
                              id="j_id_54:init-compositor-all:j_id_1nm"
                              class="ui-outputlabel ui-widget c-sb-input__label"
                              for="j_id_54:init-compositor-all:country"
                              ><span class="ui-outputlabel-label">{{
                                $func.parseIso("month")
                              }}</span></label
                            >
                            <div
                              class="c-sb-input__addicon c-sb-input__addicon--drop"
                            >
                              <select
                                id="j_id_54:init-compositor-all:country"
                                name="j_id_54:init-compositor-all:country"
                                size="1"
                                class="c-sb-input__field dev-select-country"
                                v-model="cruise.month"
                                @change="listCruises(false)"
                              >
                                <option :value="''">
                                  {{ $func.parseIso("allMonths") }}
                                </option>
                                <option
                                  :value="item[0]"
                                  v-for="item in allMonths"
                                >
                                  {{ item[2] }}
                                </option>
                              </select>
                              <i
                                class="c-sb-input__icon fa-light fa-calendar"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div :class="`o-layout__item u-1/5@xs pb-20`">
                          <div class="c-sb-input">
                            <label
                              id="j_id_54:init-compositor-all:j_id_1nm"
                              class="ui-outputlabel ui-widget c-sb-input__label"
                              for="j_id_54:init-compositor-all:country"
                              ><span class="ui-outputlabel-label">{{
                                $func.parseIso("numberOfDay")
                              }}</span></label
                            >
                            <div
                              class="c-sb-input__addicon c-sb-input__addicon--drop"
                            >
                              <select
                                id="j_id_54:init-compositor-all:country"
                                name="j_id_54:init-compositor-all:country"
                                size="1"
                                class="c-sb-input__field dev-select-country"
                                v-model="cruise.numberOfDays"
                                @change="listCruises(false)"
                              >
                                <option :value="''">
                                  {{ $func.parseIso("allLengths") }}
                                </option>
                                <option
                                  :value="item[0]"
                                  v-for="item in allLengths"
                                >
                                  {{ item[2] }}
                                </option>
                              </select>
                              <i
                                class="c-sb-input__icon fa-light fa-calendar-day"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="j_id_54:init-compositor-all:search-button-panel"
                          :class="`o-layout__item u-1/5@xs u-1/5@md pb-20`"
                        >
                          <a
                            id="j_id_54:init-compositor-all:search"
                            name="j_id_54:init-compositor-all:search"
                            class="c-sb-input__button c-button continuar bg-clr--brand u-1/1 call-pp-help dev-holidays-search-btn"
                            title="Buscar"
                            data-placement="bottom"
                            data-content="Y busque paquetes"
                            @click="resetFiltersCruises"
                            style="background-color: #707070"
                          >
                            {{
                              textLoading === ""
                                ? $func.parseIso("resetFilters")
                                : textLoading
                            }}
                          </a>
                        </div>
                        <div
                          id="j_id_54:init-compositor-all:search-button-panel"
                          :class="`o-layout__item u-1/5@xs u-1/5@md pb-20`"
                        >
                          <a
                            id="j_id_54:init-compositor-all:search"
                            name="j_id_54:init-compositor-all:search"
                            class="c-sb-input__button c-button continuar bg-clr--brand u-1/1 call-pp-help dev-holidays-search-btn"
                            title="Buscar"
                            data-placement="bottom"
                            data-content="Y busque paquetes"
                            @click="findCruises"
                            :style="
                              btnColor ? `background-color: #${btnColor};` : ''
                            "
                          >
                            {{
                              textLoading === ""
                                ? $func.parseIso("find")
                                : textLoading
                            }}
                          </a>
                        </div>

                        <!-- <div class="o-layout__item u-1/1 u-text-align--right">
                          <span class="terms-use u-font-size--default"
                            >Haciendo click en "Buscar", acepto las
                            <a
                              id="j_id_54:init-compositor-all:j_id_1ol"
                              href="#"
                              class="ui-commandlink ui-widget"
                              aria-label=""
                              onclick='PrimeFaces.ab({s:"j_id_54:init-compositor-all:j_id_1ol",f:"form",u:"@(.linkTerms)",onco:function(xhr,status,args,data){$(&apos;.linkTerms&apos;).modal(&apos;show&apos;);},pa:[{name:"modalUseTerms",value:"true"}]});return false;'
                            >
                              <span
                                class="c-inline-link c-inline-link--underline"
                                >Condiciones de uso</span
                              ></a
                            >
                          </span>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- <Footer :isDefault="club && false" :club="club" /> -->
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import {
  setSearchWeekObject,
  getClubObject,
  getMemberObject,
  getSesion,
} from "../utils/sesions";
import { detect } from "detect-browser";
const imgs = [
  "https://booking.kovayvacations.com.mx/javax.faces.resource/index-background/background-1.jpg",
  "https://booking.kovayvacations.com.mx/javax.faces.resource/index-background/background-2.jpg",
  "https://booking.kovayvacations.com.mx/javax.faces.resource/index-background/background-0.jpg",
  "https://booking.kovayvacations.com.mx/javax.faces.resource/index-background/background-5.jpg",
];

const SKIN_VACANCY = 951;

export default {
  data() {
    return {
      country: null,
      state: null,
      city: null,
      checkIn: null,
      checkOut: null,
      countries: [],
      states: [],
      cities: [],
      checkInList: [],
      checkOutList: [],
      token: null,
      club: null,
      iso: null,
      textLoading: "",
      intervalId: null,
      tab: 1,
      cruise: {
        destination: 1,
        port: "",
        cruiseline: "",
        ship: "",
        month: "",
        numberOfDays: "",
      },
      allDestinations: [],
      allPorts: [],
      allCruiseLines: [],
      allShips: [],
      allMonths: [],
      allLengths: [],
      btnColor: null,
      browser: null,
      ModuloCruceros: false,
      ModuloMyDeals: false,
      KeyMyDeals: null,
      UrlMyDeals: null,
      Nombre: null,
      Apellido: null,
      Email: null,
      LoginID: null,
      setImg: imgs[Math.floor(Math.random() * imgs.length)],
    };
  },
  components: {
    Header,
    Footer,
  },
  async created() {
    // await this.checkSesion();
    await this.listCountries();

    console.log("setImg", this.setImg);

    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
      this.$forceUpdate();
    });

    await this.listCruises();

    const __club = getClubObject();
    if (__club?.ColorPrimarioHex) this.btnColor = __club?.ColorPrimarioHex;
    if (__club?.ModuloCruceros) this.ModuloCruceros = __club?.ModuloCruceros;
    if (__club?.ModuloMyDeals) this.ModuloMyDeals = __club?.ModuloMyDeals;
    if (__club?.KeyMyDeals) this.KeyMyDeals = __club?.KeyMyDeals;
    if (__club?.UrlMyDeals) this.UrlMyDeals = __club?.UrlMyDeals;

    const __member = getMemberObject();
    if (__member?.Nombre) this.Nombre = __member?.Nombre;
    if (__member?.ApellidoPaterno) this.Apellido = __member?.ApellidoPaterno;
    if (__member?.Email) this.Email = __member?.Email;

    const __sesion = getSesion();
    if (__sesion?.LoginID) this.LoginID = __sesion?.LoginID;

    const browser = detect();
    this.browser = browser.name;
    console.log("browser.name", browser.name);
  },
  methods: {
    async listCountries() {
      try {
        const { data } = await this.$axios.get("/week/countries");
        this.countries = data.countries;
        this.countries = this.countries.sort((a, b) =>
          a.Country === "USA" ||
          a.Country === "Mexico" ||
          a.Country === "Dominican Republic"
            ? -1
            : 0
        );
        // console.log(this.countries);
      } catch (error) {
        console.log(error);
      }
    },
    async listState() {
      try {
        const { data } = await this.$axios.get(
          `/week/states?country=${this.country}`
        );
        this.states = data.states.sort((a, b) =>
          a.NOMBRE_ESTADO > b.NOMBRE_ESTADO
            ? 1
            : a.NOMBRE_ESTADO < b.NOMBRE_ESTADO
            ? -1
            : 0
        );
        // console.log(this.countries);
      } catch (error) {
        console.log(error);
      }
    },
    async listCities(state = null) {
      try {
        let query = `?country=${this.country}`;
        if (state) query += `&state=${state}`;
        const { data } = await this.$axios.get(`/week/cities${query}`);
        this.cities = data.cities;
        // console.log(this.countries);
      } catch (error) {
        console.log(error);
      }
    },
    async checkMonths() {
      if (this.country && this.city) {
        try {
          const { data } = await this.$axios.get(
            `/week/check-months?country=${this.country}&city=${this.city}`
          );
          this.checkInList = data.checkIn;
          this.checkOutList = data.checkOut;
          // console.log(this.countries);
        } catch (error) {
          console.log(error);
        }
      }
    },
    async listWeeks() {
      if (this.country && this.city && this.checkIn && this.checkOut) {
        this.textLoading += ".";
        setSearchWeekObject({
          country: this.country,
          city: this.city,
          checkIn: this.checkIn,
          checkOut: this.checkOut,
          state: this.state,
        });
        this.intervalId = setInterval(
          (_this) => {
            // console.log("_this.textLoading", _this.textLoading);
            _this.textLoading += ".";
            if (_this.textLoading.split("").length >= 4) {
              clearInterval(_this.intervalId);
              // const __path = _this.$router.resolve({ name: "ResortList" });
              _this.textLoading = "";
              _this.country = null;
              _this.state = null;
              _this.city = null;
              _this.checkIn = null;
              _this.checkOut = null;

              if (this.browser === "ios") window.location.href = "/resortlist";
              else window.open("/resortlist", "_blank");
              // _this.$router.push(`/resortlist`);
            }
          },
          800,
          this
        );
      }
    },
    checkCountry() {
      if (this.country) {
        if (this.checkFormState()) this.listState();
        else this.listCities();
      }
    },
    checkState() {
      if (this.country && this.state) this.listCities(this.state);
    },
    formatDate(date) {
      return this.$moment(date).format("MMMM - YYYY");
    },
    checkFormState() {
      if (this.country === "USA" || this.country === "Canada") return true;
      return false;
    },
    async checkSesion() {
      try {
        const { token } = this.$route.query;

        if (!token) {
          const urllogin = localStorage.getItem("UrlLogin");
          // console.log('urllogin', urllogin)
          // console.log(`https://${process.env.VUE_APP_URL_LOGIN_DEFAULT}`)
          if (urllogin) window.location.href = `https://${urllogin}`;
          else
            window.location.href = `https://${process.env.VUE_APP_URL_LOGIN_DEFAULT}`;
        }

        this.token = token;

        const { data } = await this.$axios.get(`/auth/sesion?token=${token}`);
        if (data.success) {
          this.$bus.emit("sesion", { sesion: data.sesion, data: data.data });
          if (data?.data?.club) this.club = data?.data?.club;
        } else {
          if (data.sesion)
            window.location.href = `https://${data.sesion.UrlLogin}`;
          else
            window.location.href = `https://${process.env.VUE_APP_URL_LOGIN_DEFAULT}`;
        }
        // console.log(this.countries);
      } catch (error) {
        console.log(error);
      }
    },
    selectTab(tab) {
      this.tab = tab;
    },
    async listCruises(resetFilters = false) {
      try {
        let mon = this.cruise.month;
        if (!mon || mon === "")
          mon = this.$moment()
            .add(2, "months")
            .startOf("month")
            .format("MM/DD/YYYY");

        const { data } = await this.$axios.get(
          `https://cs.cruisebase.com/csa/GetSCriteria?skin=${SKIN_VACANCY}&lid=${this.iso}&did=${this.cruise.destination}&mon=${mon}&pid=${this.cruise.port}&vid=${this.cruise.cruiseline}&sid=${this.cruise.ship}&len=${this.cruise.numberOfDays}`
        );

        if (data.zones) {
          this.allDestinations = data.zones.filter((d) => d[1] !== 1);
        }
        if (data.ports) {
          this.allPorts = data.ports.filter((d) => d[1] !== 1);
        }
        if (data.vendors) {
          this.allCruiseLines = data.vendors.filter((d) => d[1] !== 1);
        }
        if (data.ships) {
          this.allShips = data.ships.filter((d) => d[1] !== 1);
        }
        if (data.months) {
          const existsDefault = data.months.find((d) => d[1] === 2);
          if (existsDefault) this.cruise.month = existsDefault[0];
          this.allMonths = data.months.filter((d) => d[1] !== 1);
        }
        if (data.days) {
          this.allLengths = data.days.filter((d) => d[1] !== 1);
        }

        if (resetFilters) {
          this.cruise.port = "";
          this.cruise.cruiseline = "";
          this.cruise.ship = "";
          this.cruise.month = "";
          this.cruise.numberOfDays = "";
        }

        console.log("data cruises", data);
      } catch (error) {
        console.log(error);
      }
    },
    findCruises() {
      const url = `https://cs.cruisebase.com/cs/forms/CruiseResultPage.aspx?skin=${SKIN_VACANCY}&phone=&lid=${
        this.iso
      }&logo=n&mon=${this.cruise.month}&did=${this.cruise.destination}&pid=${
        this.cruise.port
      }&vid=${this.cruise.cruiseline}&sid=${this.cruise.ship}&len=${
        this.cruise.numberOfDays
      }&nr=y&look=${process.env.VUE_APP_API_URL_STATIC}/cruise_${
        (localStorage.getItem("club") &&
          localStorage.getItem("club")?.Nomenclatura) ||
        "VR"
      }.css`;
      if (this.browser === "ios") window.location.href = url;
      else window.open(url, "_blank", "noopener, noreferrer");
    },
    resetFiltersCruises() {
      this.cruise.port = "";
      this.cruise.cruiseline = "";
      this.cruise.ship = "";
      this.cruise.month = "";
      this.cruise.numberOfDays = "";
    },
    async goMyDeals() {
      try {
        const [k1, k2] = this.KeyMyDeals.split("-");

        const sha1 = require("js-sha1");

        const { data } = await axios.post(`${API_URL}/mydeals/import`, {
          members: [
            {
              organization_customer_identifier: k1,
              program_customer_identifier: k2,
              first_name: this.Nombre,
              last_name: this.Apellido,
              email_address: this.Email,
              member_customer_identifier: this.LoginID,
              member_status: "OPEN",
            },
          ],
        });

        if (data) {
          const tokenMyDeals = sha1(`${k1}-${k2}-${this.LoginID}`);
          console.log("tokenMyDeals", tokenMyDeals);

          window.open(
            `${this.UrlMyDeals}?cvt=${tokenMyDeals}`,
            "_blank",
            "noopener, noreferrer"
          );
        }
      } catch (error) {
        console.log("goMyDeals", error);
      }
    },
  },
};
</script>

<style scoped>
:root {
  --s-color-brand-primary: #0472aa;
  --s-color-brand-primary-hsl-hue: 200;
  --s-color-brand-primary-hsl-saturation: 95%;
  --s-color-brand-primary-hsl-light: 34%;
  --s-color-brand-primary-red: 4;
  --s-color-brand-primary-green: 114;
  --s-color-brand-primary-blue: 170;

  --s-color-brand-dark: #00527c;
  --s-color-brand-dark-hsl-hue: 200;
  --s-color-brand-dark-hsl-saturation: 100%;
  --s-color-brand-dark-hsl-light: 24%;
  --s-color-brand-dark-red: 0;
  --s-color-brand-dark-green: 82;
  --s-color-brand-dark-blue: 124;

  --s-color-searchbox-background: #00527c;
  --s-color-searchbox-background-hsl-hue: 200;
  --s-color-searchbox-background-hsl-saturation: 100%;
  --s-color-searchbox-background-hsl-light: 24%;
  --s-color-searchbox-background-red: 0;
  --s-color-searchbox-background-green: 82;
  --s-color-searchbox-background-blue: 124;

  --s-color-searchbox-text: #393939;
  --s-color-searchbox-text-hsl-hue: 0;
  --s-color-searchbox-text-hsl-saturation: 0%;
  --s-color-searchbox-text-hsl-light: 22%;
  --s-color-searchbox-text-red: 57;
  --s-color-searchbox-text-green: 57;
  --s-color-searchbox-text-blue: 57;

  --s-color-footer-background: #00527c;
  --s-color-footer-background-hsl-hue: 200;
  --s-color-footer-background-hsl-saturation: 100%;
  --s-color-footer-background-hsl-light: 24%;
  --s-color-footer-background-red: 0;
  --s-color-footer-background-green: 82;
  --s-color-footer-background-blue: 124;

  --s-color-footer-text: #393939;
  --s-color-footer-text-hsl-hue: 0;
  --s-color-footer-text-hsl-saturation: 0%;
  --s-color-footer-text-hsl-light: 22%;
  --s-color-footer-text-red: 57;
  --s-color-footer-text-green: 57;
  --s-color-footer-text-blue: 57;
}

.noIcon {
  background: none;
}

.c-sb-input__field {
  padding-left: 40px;
}

/* @media (max-width: 1920) {
  .img-jdaza {
    height: 109%;
  }
}
@media (max-width: 1280px) {
  .img-jdaza {
    height: 123%;
  }
} */
.img-jdaza {
  height: 109%;
}

@media (max-width: 1280px) {
  .img-jdaza {
    height: 123%;
  }
}
</style>
