import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import RecoverPassword from "../views/RecoverPassword.vue";
import ResetPasswordFirtsTime from "../views/ResetPasswordFirtsTime.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/recover-password",
    name: "RecoverPassword",
    component: RecoverPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPasswordFirtsTime",
    component: ResetPasswordFirtsTime,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
