<template>
  <div>
    <div class="main bg-clr--lighter-gray o-section--huge">
      <div
        id="signup"
        name="signup"
        style="max-width: 450px"
        class="o-box o-block o-block--small bg-clr--white u-box-shadow m-auto--sides"
      >
        <div class="o-block__item u-text-align--center">
          <b class="u-font-size--middle">{{
            $func.parseIso("changePassword")
          }}</b>
          <p class="u-font-size--small">
            {{ $func.parseIso("changePassword__msg1") }}
          </p>
          <b><div id="signup:messages"></div></b>
        </div>

        <div class="o-block__item c-icon-escort">
          <b class="u-font-size--small">
            {{ $func.parseIso("newPassword") }}
          </b>
          <span
            class="ui-password ui-password-masked ui-inputwrapper ui-input-icon-right"
            style="cursor: pointer"
          >
            <input
              v-model="password"
              id="login-form:login-content:login:j_password"
              name="login-form:login-content:login:j_password"
              :type="isShowPass ? 'text' : 'password'"
              class="ui-inputfield ui-widget ui-state-default ui-corner-all c-icon-escort__main c-text-input login-password-input" />
            <i
              id="login-form:login-content:login:j_password_mask"
              :class="!isShowPass ? 'pi pi-eye' : 'pi pi-eye-slash'"
              @click="showPass"
            >
            </i
          ></span>
          <p class="u-font-size--small" style="color: #0472aa">
            {{ $func.parseIso("changePassword__msg2") }}
          </p>
        </div>

        <div class="o-block__item c-icon-escort">
          <b class="u-font-size--small">
            {{ $func.parseIso("confirmPassword") }}
          </b>
          <span
            class="ui-password ui-password-masked ui-inputwrapper ui-input-icon-right"
            style="cursor: pointer"
          >
            <input
              v-model="checkPassword"
              id="login-form:login-content:login:j_password2"
              name="login-form:login-content:login:j_password"
              :type="isShowPass ? 'text' : 'password'"
              class="ui-inputfield ui-widget ui-state-default ui-corner-all c-icon-escort__main c-text-input login-password-input" />
            <i
              id="login-form:login-content:login:j_password_mask"
              :class="!isShowPass ? 'pi pi-eye' : 'pi pi-eye-slash'"
              @click="showPass"
            >
            </i
          ></span>
          <strong v-if="errorChangePassword"
            ><p class="u-font-size--small" style="color: #f14668">
              {{ $func.parseIso("changePassword__msg3") }}
            </p></strong
          >
          <strong v-if="notMatchPassword"
            ><p class="u-font-size--small" style="color: #f14668">
              {{ $func.parseIso("notMatchPassword") }}
            </p></strong
          >
          <strong v-if="errorChangePasswordInternal"
            ><p class="u-font-size--small" style="color: #f14668">
              {{ $func.parseIso("changePassword__msg4") }}
            </p></strong
          >
        </div>

        <div class="o-block__item">
          <button
            id="signup:submit"
            name="signup:submit"
            :class="`ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only c-button c-button--solid--default bg-clr--brand u-1/1 u-font-size--small ${
              (loadingChangePassword && 'button--loading') || ''
            }`"
            aria-label=""
            tabindex="7"
            @click="changePassword"
          >
            <span class="ui-button-text ui-c" v-if="!loadingChangePassword">{{
              $func.parseIso("changePassword")
            }}</span>
          </button>
        </div>
        <!-- <div class="o-block__item">
          {{ $func.parseIso("recoverPasswordMsg2") }}
        </div>
        <div class="o-block__item" style="cursor: pointer" @click="goToLogin">
          <a class="u-font-size--small"
            ><b>{{ $func.parseIso("login") }} </b></a
          >
        </div> -->
      </div>
    </div>
    <Alert
      :show="alert.show"
      :title="alert.title"
      :closeAlert="alert.close"
      :return="goToDashboard"
    />
  </div>
</template>

<script>
import Alert from "../components/Alert.vue";

export default {
  components: {
    Alert,
  },
  data() {
    return {
      step: 1,
      email: null,
      iso: "es",
      tokenId: null,
      password: null,
      checkPassword: null,
      isGen: false,
      emailGenv1: null,
      redirectDashboard: null,
      tokenPass: null,
      micrositePass: null,
      urlTcPass: null,
      URL_REDIRECT_DASHBOARD: "",
      errorChangePassword: false,
      notMatchPassword: false,
      errorChangePasswordInternal: false,
      alert: {
        show: false,
        title: null,
        description: null,
        close: null,
      },
      isShowPass: false,
      isShowPass2: false,
      loadingChangePassword: false,
    };
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
      this.$forceUpdate();
    });
    this.checkParams();
  },
  methods: {
    showPass() {
      this.isShowPass = !this.isShowPass;
    },
    showPass2() {
      this.isShowPass2 = !this.isShowPass2;
    },
    goToDashboard() {
      // this.$router.push("/");
      const URL_REDIRECT_DASHBOARD = `https://${this.urlTcPass}?submit=true&keepurl=true&token=${this.tokenPass}&microsite=${this.micrositePass}`;
      console.log("URL_REDIRECT_DASHBOARD", URL_REDIRECT_DASHBOARD);
      window.location.href = URL_REDIRECT_DASHBOARD;
    },
    goToTerms() {
      this.closeAlert()
      this.$bus.emit("open-terms");
    },
    async changePassword() {
      if (
        this.password &&
        this.password !== "" &&
        this.password === this.checkPassword
      ) {
        try {
          this.loadingChangePassword = true;
          this.errorChangePassword = false;
          this.notMatchPassword = false;
          this.errorChangePasswordInternal = false;

          if (this.isGen) {
            await this.$axios.post(`/recovery-password/members-gen/change`, {
              email: this.emailGenv1,
              password: this.password,
            });
          } else {
            await this.$axios.post(`/recovery-password/members/change`, {
              tokenId: this.tokenId,
              password: this.password,
            });
          }

          const { checkTerms } = this.$route.query;

          if (checkTerms && checkTerms === "true") {
            this.alert.title = this.$func.parseIso("resetPasswordOk");
            this.alert.close = this.goToDashboard;
            this.openAlert();
          }else if(checkTerms && checkTerms === "false"){
            this.alert.title = this.$func.parseIso("resetPasswordOk");
            this.alert.close = this.goToTerms;
            this.openAlert();
          }

          this.loadingChangePassword = false;
          this.$forceUpdate();
        } catch (error) {
          this.loadingChangePassword = false;
          const code = error?.response?.data?.code;
          if (code && code === 4041) this.errorChangePassword = true;
          else this.errorChangePasswordInternal = true;
          console.log(error);
        }
      } else this.notMatchPassword = true;
    },
    openAlert() {
      this.alert.show = true;
    },
    closeAlert() {
      this.alert.show = false;
    },
    closeAlertAndReturnLogin() {
      this.alert.show = false;
      this.$router.push("/");
    },
    checkParams() {
      const {
        step,
        tokenId,
        isGen,
        email,
        tokenPass,
        micrositePass,
        urlTcPass,
      } = this.$route.query;
      console.log("step", step);
      console.log("tokenId", tokenId);
      console.log("isGen", isGen);
      console.log("email", email);
      this.tokenId = tokenId;
      this.tokenPass = tokenPass || null;
      this.micrositePass = micrositePass || null;
      this.urlTcPass = urlTcPass || null;
      this.URL_REDIRECT_DASHBOARD = `https://${urlTcPass}?submit=true&keepurl=true&token=${this.tokenPass}&microsite=${this.micrositePass}`;
      // if (step && tokenId && step === "2") {
      //   this.step = 2;
      //   this.tokenId = tokenId;
      //   if (isGen) {
      //     this.isGen = true;
      //     this.emailGenv1 = email;
      //   }
      // } else this.step = 1;
    },
    validateTerms() {
      const { checkTerms } = this.$route.query;
      if (checkTerms && checkTerms === "false") {
      }
    },
  },
};
</script>

<style scoped>
.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>
