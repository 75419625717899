<template>
  <div>
    <Header />
    <div
      id="wrapper"
      class="booking-process"
      aria-busy="false"
      style="position: relative"
    >
      <div class="main only-hotel">
        <div class="header-page bg-clr--lightest-gray">
          <a
            href="/"
            data-toggle="modal"
            data-target="#composeModal"
            class="u-display--block u-display--none@md js-mobile-filters-and-views"
          >
            <div
              class="o-block--small o-box o-box--small bg-clr--lightest-gray"
            >
              <div class="o-block__item o-box--small bg-clr--white">
                <div
                  class="o-group--small o-flexbox o-flexbox--generic-row u-align-items--center u-flex--11 u-height--100"
                >
                  <div class="u-font-size--default u-line-height--1">
                    <b>
                      {{ city === "all" ? $func.parseIso("allCity") : city }},
                      {{ state && state !== "" ? state + ", " : ""
                      }}{{ country }}
                    </b>
                    <div
                      id="j_id_2j:mobile-count-legent"
                      class="u-font-size--small mt-5 count-legend"
                    >
                      Total {{ totalList }} {{ $func.parseIso("weeks") }}
                    </div>
                  </div>
                  <i
                    class="clr--brand fa-regular fa-search u-font-size--big"
                  ></i>
                </div>
              </div>
              <div
                class="o-block__item o-box--small pb-5 o-flexbox o-flexbox--generic-row bg-clr--white clr--darker-gray u-font-size--small"
              >
                <div class="">
                  <div class="o-flexbox u-flex-wrap--wrap u-gap--small">
                    <div class="o-group--tiny mb-5">
                      <i class="fa-regular fa-calendar-lines"></i
                      ><span> {{ checkIn }} - {{ checkOut }} </span>
                    </div>
                    <!-- <div class="o-group--tiny mb-5">
                      <i class="fa-regular fa-user-friends"></i
                      ><span>2 Adults </span>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <span class="js-mobile-navigation--accommodation-avail"></span>
        <div
          id="accommodation-avail:c-mobile-navigation"
          class="c-mobile-navigation c-mobile-navigation--accommodation-avail"
        >
          <div
            class="c-mobile-navigation-bar c-mobile-navigation-bar--top u-display--block u-display--none@md"
          >
            <div class="c-mobile-navigation-content-wrapper">
              <div class="c-navigation-availability">
                <a
                  href="/home?tripId=2"
                  class="c-navigation-availability__square"
                >
                  <i class="fa-regular fa-arrow-left"></i
                ></a>
                <div class="c-navigation-availability__text">
                  <div class="c-navigation-availability-title">
                    <small class="c-navigation-availability-title__small"
                      >Accommodations</small
                    ><b class="c-navigation-availability-title__medium"
                      >in Florida Keys FL</b
                    >
                  </div>
                </div>
                <div
                  data-target="#composeModal"
                  data-toggle="modal"
                  class="c-navigation-availability__edit"
                >
                  <i class="fa-regular fa-magnifying-glass"></i>
                </div>
              </div>
            </div>
          </div>
          <div
            class="c-mobile-navigation-bar c-mobile-navigation-bar--bottom u-display--block u-display--none@md"
          >
            <div class="c-mobile-navigation-content-wrapper">
              <div
                id="accommodation-avail:hotel-view-buttons-mobile"
                class="c-navigation-availability__button-group hotel-view-buttons"
              >
                <div
                  class="c-navigation-availability__button dev-availability-filters"
                >
                  <a
                    class="c-button u-min-width--auto bdr-clr--brand u-1/1 ux-open-filters u-font-size--small u-font-size--default@sm"
                    @click="openFiltersTab()"
                    ><span>{{ $func.parseIso("filters") }}</span
                    ><i class="fa-regular fa-sliders-h ml-10"></i
                  ></a>
                </div>
                <!-- <div class="c-navigation-availability__button">
                  <a
                    id="accommodation-avail:j_id_3t"
                    href="#"
                    class="ui-commandlink ui-widget c-button u-min-width--auto u-1/1 u-font-weight--700"
                    aria-label=""
                    style="
                      background-image: url('https://booking.vacancyrewards.com/javax.faces.resource/images/btn-map.png');
                    "
                  >
                    <i
                      class="fa-solid fa-map-marker-alt u-font-size--big clr--brand"
                    ></i
                  ></a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="container o-container o-container--large">
          <div class="o-layout" style="display: flex">
            <!-- Filters -->
            <ResortListFilters
              :country="country"
              :city="city"
              :state="state"
              :checkIn="checkIn"
              :checkOut="checkOut"
              :token="token"
            />
            <!-- Fin Filter -->
            <div
              class="o-layout__item u-12/12 u-9/12@md pl-0 pl-20@md pl-40@lg comp-app"
            >
              <form
                id="mainForm"
                name="mainForm"
                method="post"
                action="/accommodation/onlyAccommodationAvail.xhtml?tripId=2&amp;availPosition=1"
                onkeypress="return event.keyCode != 13;"
                enctype="application/x-www-form-urlencoded"
                data-partialsubmit="true"
              >
                <div
                  id="mainForm:hotelMapModal"
                  class="c-modal hotelMapModal c-modal--onmobile-fullscreen setup-modal-back-button"
                  data-size="large"
                  role="dialog"
                  data-closeable="true"
                  tabindex="-1"
                  aria-modal="true"
                  data-active="false"
                >
                  <div
                    class="c-modal__backmask"
                    onclick="$('.hotelMapModal').modal('hide')"
                  ></div>
                  <div class="c-modal__wrapper">
                    <div class="c-modal__container">
                      <div class="c-modal__close" style="">
                        <button
                          type="button"
                          class="c-modal__close-btn"
                          data-dismiss="modal"
                        >
                          <span
                            class="o-box--small--horizontal o-box--tiny--vertical u-font-weight--500 u-font-size--small u-border-radius--sm u-line-height--1 u-pointer-events--none"
                          >
                            Close
                            <i
                              class="fa-solid fa-caret-right u-font-size--default"
                            ></i></span
                          ><i class="u-display--block fa-regular fa-close"></i>
                        </button>
                      </div>
                      <div class="c-modal__body">
                        <div
                          class="c-modal__header u-font-weight--600 u-line-height--1 u-hide-empty"
                        ></div>
                        <div class="hotelDataSheet">
                          <div class="c-microservice c-microservice--hotel-map">
                            <div class="c-microservice__primary">
                              <div
                                class="c-microservice__main p-0 u-border--none"
                              >
                                <div class="c-microservice__body">
                                  <div class="c-microservice__content">
                                    <a
                                      href="/accommodation//waiting/1?tripId=2&amp;previousPage=%2Faccommodation%2FonlyAccommodationAvail.xhtml&amp;previousViewState=NDNhYjQ4MzMzNTIzOTQ3MjAwMDAwMDAy"
                                      id="mainForm:openHotelMiniMap"
                                      name="mainForm:openHotelMiniMap"
                                      target="_blank"
                                    >
                                      <div class="c-microservice__heading">
                                        <b
                                          class="c-microservice__title mt-5"
                                        ></b>
                                        <div class="c-hotel-status null mt-5">
                                          <span
                                            class="c-hotel-status__category null"
                                            ><i
                                              class="fa-solid fa-key c-hotel-status__star"
                                            ></i
                                            ><i
                                              class="fa-solid fa-key c-hotel-status__star"
                                            ></i
                                            ><i
                                              class="fa-solid fa-key c-hotel-status__star"
                                            ></i
                                            ><i
                                              class="fa-solid fa-key c-hotel-status__star"
                                            ></i
                                            ><i
                                              class="fa-solid fa-key c-hotel-status__star"
                                            ></i
                                            ><i
                                              class="fa-solid fa-key c-hotel-status__star"
                                            ></i
                                          ></span>
                                        </div>
                                      </div>
                                      <div
                                        class="c-microservice__details"
                                      ></div>
                                    </a>
                                  </div>
                                </div>
                                <div class="c-microservice__map-section">
                                  <div
                                    style="height: 500px"
                                    class="c-microservice__map c-microservice__map--lg"
                                    data-marker-target="_blank"
                                    data-availposition="1"
                                    data-hotelname=""
                                    data-market-feature="true &amp;&amp; !isInIframe() ? '' : travelc.pushViewStateToHistory()"
                                    data-marker-link="/accommodation/accommodationDetailWaiting.xhtml?tripId=2&amp;availPosition=1&amp;accommodationCode=&amp;previousPage=/accommodation/onlyAccommodationAvail.xhtml&amp;previousViewState=NDNhYjQ4MzMzNTIzOTQ3MjAwMDAwMDAy"
                                    data-latitude=""
                                    data-is-apartment=""
                                    data-longitude=""
                                    data-code=""
                                  >
                                    <div id="hotel-map-container"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="mainForm:hoteles"
                  class="ux-service-city-availability ux-service-city-availability--hotel o-block o-block--small parent-clr--1"
                >
                  <div
                    id="mainForm:sort-selector"
                    class="ux-service-heading o-block__item u-display--none u-display--block@md"
                  >
                    <div
                      class="o-flexbox o-flexbox--generic-column o-flexbox--generic-row@sm u-align-items--center u-gap--small"
                    >
                      <div class="o-group--tiny">
                        <h2 class="c-title--main u-font-weight--bold m-0">
                          <b>
                            {{
                              city === "all" ? $func.parseIso("allCity") : city
                            }}, {{ state && state !== "" ? state + ", " : ""
                            }}{{ country }}
                          </b>
                        </h2>
                        <span
                          id="mainForm:j_id_4o3:count-legend"
                          class="count-legend c-title--secondary"
                        >
                          Total {{ totalList }} {{ $func.parseIso("weeks") }}
                        </span>
                      </div>
                      <span>
                        <div
                          id="mainForm:j_id_4o3:sort-selector"
                          :class="`c-dropdown dropdown clr--dark-gray 
                          u-1/1 u-width--auto@sm sort-selector u-display--none 
                          u-display--inline-block@sm ${
                            openListSort ? 'open' : ''
                          }`"
                        >
                          <a
                            id="sortHotelsButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                            class="c-button dropdown-toggle ux-sort-selector sortHotelsButton"
                            @click="openListSort = !openListSort"
                            ><span
                              class="o-flexbox u-align-items--center u-min-width--auto u-gap--tiny u-font-weight--600 u-white-space--nowrap"
                              ><small
                                class="u-font-size--small u-font-weight--400"
                                >{{ $func.parseIso("orderBy") }}</small
                              >
                              {{
                                selectSort
                                  ? $func.parseIso(selectSort.nameIso)
                                  : "n/a"
                              }} </span
                            ><i
                              class="fa-regular fa-angle-down u-font-size--big"
                              aria-hidden="true"
                            ></i
                          ></a>
                          <div
                            class="dropdown-menu o-box--default"
                            aria-labelledby="sortHotelsButton"
                          >
                            <div class="o-block o-block--small">
                              <!-- <div class="o-block__item">
                                <a
                                  id="mainForm:j_id_4o3:dropdown:sortByTravelc"
                                  href="#"
                                  class="ui-commandlink ui-widget u-display--block o-box--tiny--vertical u-padding--null@sm"
                                  aria-label=""
                                >
                                  <span class="u-font-weight--bold">
                                    Our selection
                                  </span></a
                                >
                              </div> -->
                              <div
                                class="o-block__item"
                                v-for="(l, idx) in listSort"
                                :key="idx"
                              >
                                <a
                                  id="mainForm:j_id_4o3:dropdown:sortByPopularity"
                                  class="ui-commandlink ui-widget u-display--block o-box--tiny--vertical u-padding--null@sm sortByPopularity"
                                  @click="setSort(l.id, true)"
                                  style="cursor: pointer"
                                >
                                  <span
                                    :class="`${
                                      selectSort && l.id === selectSort.id
                                        ? 'u-font-weight--bold'
                                        : ''
                                    }`"
                                  >
                                    {{ $func.parseIso(l.nameIso) }}
                                  </span></a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div
                    class="o-flexbox u-align-items--start u-align-items--center@sm pt-10 pb-10 o-group--small dev-incremental-completed"
                  >
                    <span
                      class="u-position--relative"
                      style="width: 20px; height: 20px"
                      ><span
                        class="o-position o-position--center u-pointer-events--none"
                      >
                        <img
                          src="../assets/gif/success.gif"
                          style="width: 30px; margin-right: -10px"
                          alt=""
                        /> </span></span
                    ><span>{{ $func.parseIso("completeSearch") }}</span>
                  </div>

                  <div
                    id="mainForm:hotelsInfo"
                    class="hotelsInfo dev-hotels-info"
                  >
                    <span
                      class="call-pp-help"
                      data-target-pp="#linkList"
                      data-content="Search results in a list - 1"
                      data-placement="top"
                      data-search-parent="true"
                    ></span
                    ><span
                      class="call-pp-help"
                      data-target-pp=".linkMap"
                      data-content="Search results in a map - 2"
                      data-placement="bottom"
                      data-search-parent="true"
                    ></span
                    ><span
                      class="call-pp-help"
                      data-target-pp=".ui-paginator-pages .ui-paginator-page:lt(3):last"
                      data-content="See more accommodations - 3"
                      data-placement="top"
                      data-search-parent="true"
                    ></span
                    ><span
                      class="call-pp-help"
                      data-target-pp=".select-hotel:lt(1):last"
                      data-content="Select this hotel - 4"
                      data-placement="bottom"
                      data-search-parent="true"
                    ></span
                    ><span
                      class="call-pp-help"
                      data-target-pp=".c-extended__button--details:first"
                      data-content="Show selected hotel info and rooms - 5"
                      data-placement="left"
                      data-search-parent="true"
                    ></span>
                    <div class="o-block">
                      <div
                        class="o-block__item u-display--none u-display--block@md"
                      >
                        <div
                          class="o-flexbox o-flexbox--generic-row u-align-items--end o-group--small u-border--bottom bdr-clr--lighter-gray"
                        >
                          <a
                            id="mainForm:horizontalList"
                            href="#"
                            class="ui-commandlink ui-widget u-flex--11 o-group--tiny c-tab-button c-tab-button--active"
                            aria-label=""
                          >
                            <i class="fa-regular fa-th-list"></i
                            ><span class="u-display--none@md">Compact</span
                            ><span
                              class="u-display--none u-display--inline-block@md"
                              >Extended</span
                            ></a
                          >
                          <a
                            id="mainForm:linkList"
                            href="#"
                            class="ui-commandlink ui-widget u-flex--11 o-group--tiny linkList c-tab-button u-display--none u-display--inline-block@md"
                            aria-label=""
                            style="visibility: hidden"
                          >
                            <i class="fa-regular fa-th-large"></i
                            ><span>Compact</span></a
                          >
                          <a
                            id="mainForm:reducedList"
                            href="#"
                            class="ui-commandlink ui-widget u-flex--11 reducedList o-group--tiny c-tab-button"
                            aria-label=""
                            style="visibility: hidden"
                          >
                            <i class="fa-regular fa-list-alt"></i
                            ><span>Comparator</span></a
                          ><a
                            id="mainForm:linkMap"
                            href="#"
                            class="ui-commandlink ui-widget u-flex--11 o-group--tiny o-box--small--vertical o-box--default--horizontal u-text-align--center linkMap"
                            aria-label=""
                            style="
                              background-image: url('https://booking.vacancyrewards.com/javax.faces.resource/images/btn-map.png');
                              visibility: hidden;
                            "
                          >
                            <div
                              class="c-button bg-clr--brand bg-clr--1--alpha-soft"
                            >
                              <i class="fa-regular fa-map-marker-alt pr-5"></i
                              ><span>Map</span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="o-block o-block--tiny o-block__item">
                        <div
                          id="mainForm:j_id_4py_aw:remove-filter-list"
                          class="remove-filter-list"
                        ></div>
                      </div>
                      <div
                        id="mainForm:hotelViews"
                        class="o-block__item hotelViews"
                      >
                        <div
                          id="mainForm:horizontalFichas"
                          class="horizontalFichas"
                        >
                          <div
                            id="mainForm:datascrollHorizontal"
                            class="ui-dataview ui-widget pf-dataListPaginator ux-options-service ui-dataview-grid"
                          >
                            <div
                              class="ui-dataview-header ui-widget-header ui-helper-clearfix ui-corner-top"
                            >
                              <div
                                class="ui-dataview-layout-options ui-selectonebutton ui-buttonset"
                              ></div>
                            </div>
                            <!-- Item -->
                            <ResortListItem
                              v-show="loading"
                              v-for="(weekl, ida) in weeksLoading"
                              :week="weekl"
                              :key="`${ida}loading`"
                              :token="token"
                            />
                            <ResortListItem
                              v-show="!loading"
                              v-for="(week, idx) in weeks"
                              :week="week"
                              :key="idx"
                              :token="token"
                            />
                            <!-- Fin Item -->

                            <!-- Pagiante -->
                            <ResortListPaginate
                              :actualPage="page"
                              :totalPage="totalPage"
                              :total="totalList"
                              :limit="limit"
                              :country="country"
                              :city="city"
                              :checkIn="checkIn"
                              :checkOut="checkOut"
                              :token="token"
                            />
                            <!-- Fin Paginate -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Footer :isDefault="club && false" :club="club" /> -->
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

import ResortListItem from "../components/ResortList/ResortListItem.vue";
import ResortListFilters from "../components/ResortList/ResortListFilters.vue";
import ResortListPaginate from "../components/ResortList/ResortListPaginate.vue";

import {
  getSearchWeekObject,
  getTokenTC,
  setSearchWeekObject,
  setSortWeeksObject,
  getSortWeeksObject,
} from "../utils/sesions";

export default {
  components: {
    Header,
    Footer,
    ResortListItem,
    ResortListFilters,
    ResortListPaginate,
  },
  // props: ["items"],
  data() {
    const weeksLoading = [
      {
        ResortName: this.$func.parseIso("loadingResortName") + "...",
        KitchenType: "n/a",
        UnitType: "n/a",
        Cost: "0.00 ...",
        imgs: [
          "https://www.downgraf.com/wp-content/uploads/2019/05/Loader-animation-principle-freebie.gif",
        ],
      },
      {
        ResortName: this.$func.parseIso("loadingResortName") + "...",
        KitchenType: "n/a",
        UnitType: "n/a",
        Cost: "0.00 ...",
        imgs: [
          "https://www.downgraf.com/wp-content/uploads/2019/05/Loader-animation-principle-freebie.gif",
        ],
      },
    ];

    return {
      weeks: [],
      weeksLoading: [...weeksLoading, ...weeksLoading, ...weeksLoading],
      country: null,
      city: null,
      checkIn: null,
      checkOut: null,
      token: null,
      club: null,
      iso: null,
      page: 1,
      limit: 10,
      totalPage: 1,
      totalList: 0,
      loading: true,
      checkFilters: null,
      listSort: [
        {
          nameIso: "priceLowest",
          value: { Cost: 1 },
          id: 1,
        },
        {
          nameIso: "priceHighest",
          value: { Cost: -1 },
          id: 2,
        },
        {
          nameIso: "checkInOrderHighest",
          value: { CheckInDate: -1 },
          id: 3,
        },
        {
          nameIso: "checkInOrderLowest",
          value: { CheckInDate: 1 },
          id: 4,
        },
      ],
      selectSort: null,
      openListSort: false,
      weekType: null,
      occupancy: null,
      capacity: null,
      kitchen: null,
    };
  },
  async created() {
    // await this.checkSesion();
    await this.checkParams();

    const tmpSort = await getSortWeeksObject();
    if (!tmpSort) await this.setSort(1);
    else {
      this.selectSort = tmpSort;
    }

    await this.listWeeks();

    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
      this.$forceUpdate();
    });
    this.$bus.on("change-page", async () => {
      this.loading = true;
      window.scrollTo(0, 0);
      await this.checkParams();
      await this.listWeeks();
      this.$forceUpdate();
    });
    this.$bus.on("change-filter", async () => {
      this.loading = true;
      window.scrollTo(0, 0);
      await this.checkParams();
      await this.listWeeks();
      this.$forceUpdate();
    });
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    async checkParams() {
      // console.log(this.$route.query)

      const token = getTokenTC();
      this.token = token;

      const searchWeek = getSearchWeekObject();

      if (!searchWeek) this.$router.push("/");

      const {
        country,
        state,
        city,
        checkIn,
        checkOut,
        page,
        limit,
        type,
        occupancy,
        capacity,
        kitchen,
        resort,
      } = searchWeek;

      this.country = country || null;
      this.state = state || null;
      this.city = city || null;
      this.checkIn = checkIn || null;
      this.checkOut = checkOut || null;
      this.page = page || 1;
      this.limit = limit || 10;
      this.weekType = type || null;
      this.occupancy = occupancy || null;
      this.capacity = capacity || null;
      this.kitchen = kitchen || null;
      this.resort = resort || null;
    },
    async listWeeks() {
      if (this.country && this.city && this.checkIn && this.checkOut) {
        try {
          let payload = {
            country: this.country,
            city: this.city,
            dateIn: this.checkIn,
            dateOut: this.checkOut,
          };
          if (this.state) payload.state = this.state;

          if (this.selectSort) payload.sort = this.selectSort.value;

          /**
           * Filtros
           */
          if (this.weekType) payload.InvTypeInDetail = this.weekType;
          if (this.occupancy) payload.MaxOccupancy = this.occupancy;
          if (this.capacity) payload.UnitType = this.capacity;
          if (this.kitchen) payload.KitchenType = this.kitchen;
          if (this.resort) payload.ResortName = this.resort;

          const { data } = await this.$axios.post(
            `/week/list?limit=${this.limit}&page=${this.page}&isPaginate=s`,
            {
              ...payload,
            }
          );
          this.weeks = data.weeks;
          this.totalPage = data.paginate.totalPages;
          this.totalList = data.paginate.total;
          this.checkFilters = data?.filters || null;

          this.loading = false;
          // console.log("this.totalPage listWeeks", this.totalPage);
          this.$bus.emit("refresh-page", this.totalPage);
          this.$bus.emit("refresh-filters", this.checkFilters);
          // console.log(this.countries);
        } catch (error) {
          console.log(error);
        }
      } else this.$router.push("/");
    },
    async checkSesion() {
      try {
        const { token } = this.$route.query;

        if (!token) {
          const urllogin = localStorage.getItem("UrlLogin");
          // console.log('urllogin', urllogin)
          // console.log(`https://${process.env.VUE_APP_URL_LOGIN_DEFAULT}`)
          if (urllogin) window.location.href = `https://${urllogin}`;
          else
            window.location.href = `https://${process.env.VUE_APP_URL_LOGIN_DEFAULT}`;
        }

        this.token = token;

        const { data } = await this.$axios.get(`/auth/sesion?token=${token}`);
        if (data.success) {
          this.$bus.emit("sesion", { sesion: data.sesion, data: data.data });
          if (data?.data?.club) this.club = data?.data?.club;
        } else {
          if (data.sesion)
            window.location.href = `https://${data.sesion.UrlLogin}`;
          else
            window.location.href = `https://${process.env.VUE_APP_URL_LOGIN_DEFAULT}`;
        }
        // console.log(this.countries);
      } catch (error) {
        console.log(error);
      }
    },
    setSort(id, execListWeeks = false) {
      const sort = this.listSort.find((l) => l.id === id);
      this.selectSort = sort;
      this.openListSort = false;
      setSortWeeksObject(sort);
      if (execListWeeks) {
        this.loading = true;
        this.listWeeks();
      }
    },
    openFiltersTab() {
      this.$bus.emit("open-filters");
    },
  },
};
</script>

<style scoped>
:root {
  --s-color-brand-primary: #0472aa;
  --s-color-brand-primary-hsl-hue: 200;
  --s-color-brand-primary-hsl-saturation: 95%;
  --s-color-brand-primary-hsl-light: 34%;
  --s-color-brand-primary-red: 4;
  --s-color-brand-primary-green: 114;
  --s-color-brand-primary-blue: 170;

  --s-color-brand-dark: #00527c;
  --s-color-brand-dark-hsl-hue: 200;
  --s-color-brand-dark-hsl-saturation: 100%;
  --s-color-brand-dark-hsl-light: 24%;
  --s-color-brand-dark-red: 0;
  --s-color-brand-dark-green: 82;
  --s-color-brand-dark-blue: 124;

  --s-color-searchbox-background: #00527c;
  --s-color-searchbox-background-hsl-hue: 200;
  --s-color-searchbox-background-hsl-saturation: 100%;
  --s-color-searchbox-background-hsl-light: 24%;
  --s-color-searchbox-background-red: 0;
  --s-color-searchbox-background-green: 82;
  --s-color-searchbox-background-blue: 124;

  --s-color-searchbox-text: #393939;
  --s-color-searchbox-text-hsl-hue: 0;
  --s-color-searchbox-text-hsl-saturation: 0%;
  --s-color-searchbox-text-hsl-light: 22%;
  --s-color-searchbox-text-red: 57;
  --s-color-searchbox-text-green: 57;
  --s-color-searchbox-text-blue: 57;

  --s-color-footer-background: #00527c;
  --s-color-footer-background-hsl-hue: 200;
  --s-color-footer-background-hsl-saturation: 100%;
  --s-color-footer-background-hsl-light: 24%;
  --s-color-footer-background-red: 0;
  --s-color-footer-background-green: 82;
  --s-color-footer-background-blue: 124;

  --s-color-footer-text: #393939;
  --s-color-footer-text-hsl-hue: 0;
  --s-color-footer-text-hsl-saturation: 0%;
  --s-color-footer-text-hsl-light: 22%;
  --s-color-footer-text-red: 57;
  --s-color-footer-text-green: 57;
  --s-color-footer-text-blue: 57;
}

.noIcon {
  background: none;
}

.c-sb-input__field {
  padding-left: 40px;
}
</style>
