<template>
  <div>
    <div class="main bg-clr--lighter-gray o-section--huge" v-if="step === 1">
      <div
        id="signup"
        name="signup"
        style="max-width: 450px"
        class="o-box o-block o-block--small u-text-align--center bg-clr--white u-box-shadow m-auto--sides"
      >
        <div class="o-block__item">
          <b class="u-font-size--middle">{{
            $func.parseIso("recoverPassword")
          }}</b>
          <p class="u-font-size--small">
            {{ $func.parseIso("recoverPasswordMsg") }}
          </p>
          <b><div id="signup:messages"></div></b>
        </div>
        <div class="o-block__item c-icon-escort">
          <input
            id="signup:name"
            name="signup:name"
            type="text"
            class="c-icon-escort__main c-text-input"
            tabindex="1"
            placeholder="..."
            v-model="email"
          />
          <i
            class="c-icon-escort__icon fa-regular fa-user"
            aria-hidden="true"
          ></i>
        </div>
        <div class="o-block__item">
          <button
            id="signup:submit"
            name="signup:submit"
            class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only c-button c-button--solid--default bg-clr--brand u-1/1 u-font-size--small"
            aria-label=""
            tabindex="7"
            @click="sendEmail"
          >
            <span class="ui-button-text ui-c">{{
              $func.parseIso("next")
            }}</span>
          </button>
        </div>
        <div class="o-block__item">
          {{ $func.parseIso("recoverPasswordMsg2") }}
        </div>
        <div class="o-block__item" style="cursor: pointer" @click="goToLogin">
          <a class="u-font-size--small"
            ><b>{{ $func.parseIso("login") }} </b></a
          >
        </div>
      </div>
    </div>
    <div
      class="o-section--bigger"
      style="border-top: 1px #cdf5fd solid"
      v-if="step === 2"
    >
      <div class="password-reset o-container o-container--small">
        <div id="signup" name="signup">
          <div class="o-box--bigger o-block">
            <div class="o-block__item">
              <input
                type="hidden"
                id="signup:j_id_b"
                name="signup:j_id_b"
                value="jose.daza@vacancyrewards.com"
              />
              <span class="u-font-size--big">{{
                $func.parseIso("resetPassword")
              }}</span>
            </div>
            <div class="o-block o-block--tiny o-block__item">
              <div class="o-block__item">
                <div
                  id="signup:j_id_g"
                  role="alert"
                  aria-atomic="true"
                  aria-live="polite"
                  data-display="both"
                  data-target="signup:password"
                  data-redisplay="true"
                  class="ui-message"
                ></div>
              </div>
              <div class="o-block__item">
                <div
                  id="signup:j_id_i"
                  role="alert"
                  aria-atomic="true"
                  aria-live="polite"
                  data-display="both"
                  data-target="signup:password_confirmation"
                  data-redisplay="true"
                  class="ui-message"
                ></div>
              </div>
            </div>
            <div class="o-block__item">
              <p
                class="u-font-size--small"
                style="
                  text-align: center;
                  margin-bottom: 10px;
                  border: 1px solid #0472aa;
                  padding: 10px;
                  color: white;
                  background-color: #0472aa;
                "
              >
                {{ $func.parseIso("changePassword__msg2") }}
              </p>
              <div
                class="o-layout o-layout--gutter-small o-layout--bottom o-block u-font-size--small"
              >
                <!-- <div class="o-layout__item o-block__item u-1/3@md">
                  <label
                    id="signup:j_id_k"
                    class="ui-outputlabel ui-widget clr--darker-gray ui-required"
                    for="signup:password"
                    >{{ $func.parseIso("newPassword")
                    }}<span class="ui-outputlabel-rfi">*</span></label
                  >
                  <div class="c-icon-escort">
                    <input
                      id="signup:password"
                      name="signup:password"
                      class="ui-password ui-inputfield ui-widget ui-state-default ui-corner-all c-icon-escort__main c-text-input ui-state-filled"
                      aria-required="true"
                      tabindex="4"
                      data-p-maxlength="255"
                      data-p-minlength="0"
                      data-p-required="true"
                      data-p-val="NotNull,Size"
                      autocomplete="new-password"
                      placeholder="..."
                      :type="isShowPass ? 'text' : 'password'"
                      v-model="password"
                    />
                    <i
                      class="c-icon-escort__icon fa-regular fa-eye"
                      style="cursor: pointer"
                      @click="showPass"
                    ></i>
                  </div>
                </div> -->
                <div class="o-layout__item o-block__item u-1/3@md">
                  <b class="u-font-size--small">
                    {{ $func.parseIso("newPassword") }}
                  </b>
                  <div class="c-icon-escort">
                    <span
                      class="ui-password ui-password-masked ui-inputwrapper ui-input-icon-right"
                      style="cursor: pointer"
                    >
                      <input
                        v-model="password"
                        id="login-form:login-content:login:j_password"
                        name="login-form:login-content:login:j_password"
                        :type="isShowPass ? 'text' : 'password'"
                        class="ui-inputfield ui-widget ui-state-default ui-corner-all c-icon-escort__main c-text-input login-password-input"
                        autocomplete="current-password"
                        placeholder="..." />
                      <i
                        id="login-form:login-content:login:j_password_mask"
                        :class="!isShowPass ? 'pi pi-eye' : 'pi pi-eye-slash'"
                        @click="showPass"
                      >
                      </i
                    ></span>
                  </div>
                </div>
                <div class="o-layout__item o-block__item u-1/3@md">
                  <b class="u-font-size--small">
                    {{ $func.parseIso("confirmPassword") }}
                  </b>
                  <div class="c-icon-escort">
                    <span
                      class="ui-password ui-password-masked ui-inputwrapper ui-input-icon-right"
                      style="cursor: pointer"
                    >
                      <input
                        v-model="checkPassword"
                        id="login-form:login-content:login:j_password2"
                        name="login-form:login-content:login:j_password"
                        :type="isShowPass ? 'text' : 'password'"
                        class="ui-inputfield ui-widget ui-state-default ui-corner-all c-icon-escort__main c-text-input login-password-input"
                        autocomplete="current-password"
                        placeholder="..." />
                      <i
                        id="login-form:login-content:login:j_password_mask"
                        :class="!isShowPass ? 'pi pi-eye' : 'pi pi-eye-slash'"
                        @click="showPass"
                      >
                      </i
                    ></span>
                  </div>
                </div>
                <div
                  class="o-layout__item o-block__item u-1/3@md mb-20 u-text-align--right u-text-align--left@md"
                >
                  <button
                    id="signup:j_id_u"
                    name="signup:j_id_u"
                    :class="`ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only c-button c-button--free-width bg-clr--brand dev-reset-pass ${
                      (loadingChangePassword && 'button--loading') || ''
                    }`"
                    aria-label=""
                    tabindex="7"
                    @click="changePassword"
                    style="width: 100%"
                  >
                    <span
                      class="ui-button-text ui-c"
                      :style="(loadingChangePassword && 'color: #0472AA') || ''"
                      >{{ $func.parseIso("resetPassword") }}</span
                    >
                  </button>
                </div>
              </div>
              <strong v-if="showError1"
                ><p class="u-font-size--small" style="color: #f14668">
                  Error: {{ $func.parseIso("changePassword__msg3") }}
                </p></strong
              >
              <strong v-if="showError2"
                ><p class="u-font-size--small" style="color: #f14668">
                  Error: {{ $func.parseIso("notMatchPassword") }}
                </p></strong
              >
              <strong v-if="showError3"
                ><p class="u-font-size--small" style="color: #f14668">
                  Error: {{ $func.parseIso("changePassword__msg4") }}
                </p></strong
              >
              <strong v-if="showError4"
                ><p class="u-font-size--small" style="color: #f14668">
                  Error: {{ $func.parseIso("changePassword__msg5") }}
                </p></strong
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Alert
      :show="alert.show"
      :title="alert.title"
      :closeAlert="alert.close"
      :return="goToLogin"
    />
  </div>
</template>

<script>
import Alert from "../components/Alert.vue";

export default {
  components: {
    Alert,
  },
  data() {
    return {
      step: 1,
      email: null,
      iso: "es",
      tokenId: null,
      password: null,
      checkPassword: null,
      isGen: false,
      emailGenv1: null,
      alert: {
        show: false,
        title: null,
        description: null,
        close: null,
      },
      isShowPass: false,
      isShowPass2: false,
      loadingChangePassword: false,
      showError1: false,
      showError2: false,
      showError3: false,
      showError4: false,
    };
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
      this.$forceUpdate();
    });
    this.checkParams();
  },
  methods: {
    showPass() {
      this.isShowPass = !this.isShowPass;
    },
    showPass2() {
      this.isShowPass2 = !this.isShowPass2;
    },
    goToLogin() {
      this.$router.push("/");
    },
    async sendEmail() {
      try {
        await this.$axios.get(
          `/recovery-password/members/send?email=${this.email}`
        );
        this.email = null;
        this.alert.title = this.$func.parseIso("passwordRecovery");
        this.alert.close = this.closeAlert;
        this.openAlert();
        this.$forceUpdate();
      } catch (error) {
        // const msg = error?.response?.data?.error?.code
        this.alert.title = this.$func.parseIso("passwordRecovery");
        this.alert.close = this.closeAlert;
        this.openAlert();
        console.log(error);
      }
    },
    async changePassword() {
      this.showError1 = false;
      this.showError2 = false;
      this.showError3 = false;
      this.showError4 = false;
      if (
        this.password &&
        this.password !== "" &&
        this.password === this.checkPassword
      ) {
        try {
          this.loadingChangePassword = true;
          if (this.isGen) {
            await this.$axios.post(`/recovery-password/members-gen/change`, {
              email: this.emailGenv1,
              password: this.password,
            });
          } else {
            await this.$axios.post(`/recovery-password/members/change`, {
              tokenId: this.tokenId,
              password: this.password,
            });
          }

          this.alert.title = this.$func.parseIso("resetPasswordOk");
          this.alert.close = this.closeAlertAndReturnLogin;
          this.openAlert();
          this.loadingChangePassword = false;
          this.$forceUpdate();
        } catch (error) {
          this.loadingChangePassword = false;
          const codeError = error?.response?.data?.code || 405;
          // const msgErrorLogin =
          //   error?.response?.data?.message ||
          //   this.$func.parseIso("internalError");
          if (codeError === 4041) {
            this.showError1 = true;
          } else if (codeError === 4042) {
            this.showError4 = true;
          } else {
            this.showError3 = true;
          }
          console.log(error);
        }
      } else this.showError2 = true;
      // else alert(this.$func.parseIso("notMatchPassword"));
    },
    openAlert() {
      this.alert.show = true;
    },
    closeAlert() {
      this.alert.show = false;
    },
    closeAlertAndReturnLogin() {
      this.alert.show = false;
      this.$router.push("/");
    },
    checkParams() {
      const { step, tokenId, isGen, email } = this.$route.query;
      console.log("step", step);
      console.log("tokenId", tokenId);
      console.log("isGen", isGen);
      console.log("email", email);
      if (step && tokenId && step === "2") {
        this.step = 2;
        this.tokenId = tokenId;
        if (isGen) {
          this.isGen = true;
          this.emailGenv1 = email;
        }
      } else this.step = 1;
    },
    goToLogin() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>
