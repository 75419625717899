<template>
  <div v-show="show">
    <!-- <link
      rel="icon"
      href="https://tr2storage.blob.core.windows.net/agencylogos/dyLuAnkKh4o7JeXjJF-wgCxSSi2863JnzA.png"
      type="image/x-icon"
    />
    <link
      rel="shortcut icon"
      href="https://tr2storage.blob.core.windows.net/agencylogos/dyLuAnkKh4o7JeXjJF-wgCxSSi2863JnzA.png"
      type="image/x-icon"
    />
    <link
      rel="apple-touch-icon"
      href="https://tr2storage.blob.core.windows.net/agencylogos/dyLuAnkKh4o7JeXjJF-wgCxSSi2863JnzA.png"
      type="image/x-icon"
    /> -->
    <HeaderLogin
      v-if="
        routerName === 'Login' ||
        routerName === 'RecoverPassword' ||
        routerName === 'ResetPasswordFirtsTime'
      "
      :isDefault="isDefault"
      :club="club"
    />
    <!-- <Header/> -->
    <router-view />
    <div
      class="c-whatsapp-button o-position--fixed u-zindex--8 u-pointer-events--none"
    >
      <a
        href="https://api.whatsapp.com/send/?phone=+5219987643785&amp;text=Hello, I would like to request information about ...&amp;type=phone_number&amp;app_absent=0"
        target="_blank"
        ><div
          class="c-whatsapp-button__wrapper o-flexbox u-align-items--center u-gap--middle"
        >
          <span
            class="c-whatsapp-button__tag u-border-radius bg-clr--white clr--dark-gray o-box--tiny--vertical o-box--default--horizontal u-box-shadow--extended u-transition"
            >Contact us</span
          >
          <div
            class="c-whatsapp-button__element o-flexbox o-flexbox--generic-row u-align-items--center u-justify-content--center bg-clr--whatsapp clr--white u-box-shadow--extended u-pointer-events--auto"
          >
            <i class="fa-brands fa-whatsapp"></i>
          </div></div
      ></a>
    </div>
    <Footer :isDefault="isDefault" :club="club" v-if="footerShow" />
  </div>
</template>

<script>
const {
  VUE_APP_ICON_DEFAULT,
  VUE_APP_NAME_DEFAULT,
  VUE_APP_URL_WEEKS_DEFAULT,
  VUE_APP_URL_LOGIN_DEFAULT,
} = process.env;
import Footer from "./components/Footer.vue";
import HeaderLogin from "./components/HeaderLogin.vue";
import {
  setClubObject,
  setIsDefault,
  setTokenTCAndExpireToken,
  getTokenTC,
  checkExpTokenTC,
  setMemberObject,
} from "./utils/sesions";

export default {
  components: {
    Footer,
    HeaderLogin,
  },
  data() {
    return {
      icon: VUE_APP_ICON_DEFAULT,
      name: VUE_APP_NAME_DEFAULT,
      routerName: null,
      club: null,
      isDefault: false,
      show: false,
      member: null,
      footerShow: true,
      versionPage: "v05022025",
    };
  },
  async created() {
    try {
      this.$bus.on("footer-show", (show) => {
        this.footerShow = show;
        this.$forceUpdate();
      });

      const iso = localStorage.getItem("iso");
      if (!iso) {
        localStorage.setItem("iso", "en");
        this.$bus.emit("change-iso");
      }

      await this.checkLogin();
      await this.checkWeeks();
      await this.insertIcon();
      await this.insertName();

      // await this.showDataPage();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    insertIcon() {
      console.log("------ CHECK ICON ------", "show --> ", this.show);
      let link = null;
      link = document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = this.icon;
      document.getElementsByTagName("head")[0].appendChild(link);
    },

    insertName() {
      console.log("------ CHECK NAME ------", "show --> ", this.show);
      let text = document.createTextNode(this.name);
      let title = document.createElement("title");
      title.appendChild(text);
      document.getElementsByTagName("head")[0].appendChild(title);
    },

    checkLogin() {
      return new Promise(async (resolve, reject) => {
        console.log("------ CHECK LOGIN ------", "show --> ", this.show);
        try {
          const { host } = window.location;
          const {
            name,
            query: { token },
          } = this.$route || { name: null, query: { token: null } };
          this.routerName = name;
          console.log("name", name, "host", host, "token", token);

          if (
            name === "Login" ||
            name === "RecoverPassword" ||
            name === "ResetPasswordFirtsTime"
          ) {
            if (token) {
              const { data } = await this.$axios.get(
                `/auth/sesion?token=${token}`
              );

              this.club = data?.data?.club || {
                UrlWeek: data?.sesion?.UrlWeek,
                UrlLogin: data?.sesion?.UrlLogin,
                LogoIcon: data?.sesion?.ClubLogoIcon,
                Logo: data?.sesion?.ClubLogo,
                Nombre: data?.sesion?.ClubName,
              };
              setClubObject(this.club);

              if (this.club) {
                this.icon = this.club?.LogoIcon;
                this.name = this.club?.Nombre;
              }

              this.member = data?.data?.member;
              setMemberObject(this.member);

              const UrlLogin = this.club?.UrlLogin;
              if (UrlLogin) {
                window.location.href = `https://${UrlLogin}`;
              }
            } else {
              const { data } = await this.$axios.get(`/auth/club?host=${host}`);

              this.club = data.club;
              console.log("1. this.club", this.club);
              setClubObject(this.club);

              if (this.club) {
                this.icon = this.club?.LogoIcon;
                this.name = this.club?.Nombre;
              }

              this.isDefault = data.default;
              setIsDefault(this.isDefault);
            }
            this.show = true;
          }

          return resolve();
        } catch (error) {
          console.log(error);
          return reject(error);
          // this.$router.go(0);
        }
      });
    },
    checkToken(token, host) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await this.$axios.get(`/auth/sesion?token=${token}`);

          console.log("checkToken", data);
          const isoToken = "en";
          if (data?.contract?.Idioma)
            isoToken = (data?.contract?.Idioma === "ES" && "es") || "en";

          if (data.success) {
            const expTokenTC = data?.sesion?.Expiracion;
            setTokenTCAndExpireToken(token, expTokenTC, data?.sesion);

            this.club = data?.data?.club || {
              UrlWeek: data?.sesion?.UrlWeek,
              LogoIcon: data?.sesion?.ClubLogoIcon,
              Logo: data?.sesion?.ClubLogo,
              Nombre: data?.sesion?.ClubName,
            };
            console.log("this.club", this.club);
            setClubObject(this.club);

            if (this.club) {
              this.icon = this.club?.LogoIcon;
              this.name = this.club?.Nombre;
            }

            this.member = data?.data?.member;
            setMemberObject(this.member);

            const UrlWeek = this.club.UrlWeek;
            const rgxHost = new RegExp(host, "ig");
            // console.log("host", host);
            // console.log("UrlWeek", UrlWeek);
            // console.log("VUE_APP_URL_WEEKS_DEFAULT", VUE_APP_URL_WEEKS_DEFAULT);
            // console.log("!rgxHost.test(UrlWeek)", !rgxHost.test(UrlWeek));
            // console.log(
            //   "UrlWeek.toString() !== host.toString()",
            //   UrlWeek.toString() !== host.toString()
            // );

            console.log("UrlWeek", UrlWeek);
            console.log("host", host);
            console.log("VUE_APP_URL_WEEKS_DEFAULT", VUE_APP_URL_WEEKS_DEFAULT);

            if (UrlWeek && UrlWeek.toString() !== host.toString()) {
              console.log("Go to UrlWeek");
              window.location.href = `https://${UrlWeek}?token=${token}&lang=${isoToken}`;
            }
            if (!UrlWeek && VUE_APP_URL_WEEKS_DEFAULT === host) {
              console.log("Go to VUE_APP_URL_WEEKS_DEFAULT");
              this.isDefault = true;
              window.location.href = `https://${process.env.VUE_APP_URL_WEEKS_DEFAULT}?token=${token}&lang=${isoToken}`;
            }
          } else {
            const UrlLogin =
              data?.data?.club?.UrlLogin || data?.sesion?.UrlLogin;
            if (UrlLogin) window.location.href = `https://${UrlLogin}`;
            else {
              window.location.href = `https://${VUE_APP_URL_LOGIN_DEFAULT}`;
              this.isDefault = true;
            }
          }
          setIsDefault(this.isDefault);
          return resolve();
        } catch (error) {
          console.log(error);
          return reject(error);
        }
      });
    },
    checkWeeks() {
      return new Promise(async (resolve, reject) => {
        console.log("------ CHECK WEEKS ------", "show --> ", this.show);
        try {
          const { host } = window.location;
          const {
            name,
            query: { token },
          } = this.$route || { name: null, query: { token: null } };
          this.routerName = name;
          console.log("name", name, "host", host, "token", token);

          if (
            name !== "Login" &&
            name !== "RecoverPassword" &&
            name !== "ResetPasswordFirtsTime"
          ) {
            if (token) {
              await this.checkToken(token, host);
              this.$bus.emit("change-data");
            } else if (
              !token &&
              (name === "Resort" || name === "AddTraveler")
            ) {
              const resClub = await this.checkClub();
              console.log("resClub", resClub);
            } else {
              const tokenTC = getTokenTC();
              const expTokenTC = checkExpTokenTC();

              console.log("tokenTC", tokenTC);
              console.log("expTokenTC", expTokenTC);

              if (tokenTC && expTokenTC) {
                await this.checkToken(tokenTC, host);
                this.$bus.emit("change-data");
              } else {
                window.location.href = `https://${VUE_APP_URL_LOGIN_DEFAULT}`;
                this.isDefault = true;
                setIsDefault(this.isDefault);
              }
            }
            this.show = true;
          }
          return resolve();
        } catch (error) {
          console.log(error);
          return reject(error);
          // this.$router.go(0);
        }
      });
    },
    checkClub() {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await this.$axios.get(`/auth/club`);
          if (data?.club) setClubObject(data?.club);
          this.club = data.club;
          return resolve(true);
          // this.isDefault = data.default;
        } catch (error) {
          console.log(error);
          return resolve(false);
        }
      });
    },
    showDataPage() {
      setInterval(
        (_this) => {
          console.log("--- showDataPage ---");
          console.log("this.$route.name", _this.$route.name);
          console.log("this.versionPage", _this.versionPage);
          console.log("--- showDataPage ---");
        },
        15000,
        this
      );
    },
  },
};
</script>

<style scoped>
.d-box {
  display: -webkit-box !important;
}

.flex-nw {
  flex-wrap: nowrap !important;
}
</style>

<style>
html {
  scroll-behavior: smooth;
}
</style>
