module.exports = {
  welcome: {
    es: "Bienvenido",
    en: "Welcome",
  },
  loginToContinue: {
    es: "Inicia sesión para continuar",
    en: "Log in to continue",
  },
  spanish: {
    es: "Español",
    en: "Spanish",
  },
  english: {
    es: "Ingles",
    en: "English",
  },
  loginIdOrEmail: {
    es: "LoginID o Correo electrónico",
    en: "LoginID or Email",
  },
  password: {
    es: "Contraseña",
    en: "Password",
  },
  login: {
    es: "Iniciar sesión",
    en: "LogIn",
  },
  forgotYourPassword: {
    es: "¿Olvidaste tu contraseña?",
    en: "Forgot your password?",
  },
  socialNetworks: {
    es: "Redes sociales",
    en: "Social networks",
  },
  contact: {
    es: "Contacto",
    en: "Contact",
  },
  cookiesPolicy: {
    es: "Política de cookies",
    en: "Cookies policy",
  },
  privacyPolicy: {
    es: "Política de privacidad",
    en: "Privacy Policy",
  },
  weeks: {
    es: "Semanas",
    en: "Weeks",
  },
  country: {
    es: "País",
    en: "Country",
  },
  countrySelected: {
    es: "Selecciona un país",
    en: "Select a country",
  },
  state: {
    es: "Estado",
    en: "State",
  },
  stateSelected: {
    es: "Selecciona un estado",
    en: "Select a state",
  },
  city: {
    es: "Ciudad",
    en: "City",
  },
  citySelected: {
    es: "Selecciona una ciudad",
    en: "Select a city",
  },
  allCity: {
    es: "Todas las ciudades",
    en: "All cities",
  },
  checkIn: {
    es: "Check In",
    en: "Check In",
  },
  checkOut: {
    es: "Check Out",
    en: "Check Out",
  },
  totalPrice: {
    es: "Precio Total",
    en: "Total price",
  },
  moreDetails: {
    es: "Más detalles",
    en: "More details",
  },
  nonRefundable: {
    es: "No reembolsable",
    en: "Non Refundable",
  },
  agency: {
    es: "Agencia",
    en: "Agency",
  },
  interestedLinks: {
    es: "Enlaces de interés",
    en: "Interest links",
  },
  commentsSupport: {
    es: "Comentarios/Soporte",
    en: "Comments/Support",
  },
  hotelsFlightsMore: {
    es: "Hoteles, vuelos y más ...",
    en: "Hotels, flights and more ...",
  },
  myProfile: {
    es: "Mi perfil",
    en: "My profile",
  },
  find: {
    es: "Buscar",
    en: "Search",
  },
  nights: {
    es: "Noches",
    en: "Nights",
  },
  pay: {
    es: "Pagar",
    en: "Pay",
  },
  morePictures: {
    es: "Más fotos",
    en: "More pictures",
  },
  aboutProperty: {
    es: "Sobre la Propiedad",
    en: "About the Property",
  },
  services: {
    es: "Servicios",
    en: "Services",
  },
  policies: {
    es: "Políticas",
    es: "Policies",
  },
  importantInformation: {
    es: "Información Importante",
    en: "Important Information",
  },
  payNow: {
    es: "Pagar ahora",
    en: "Pay now",
  },
  successfulPayment: {
    es: "Pago exitoso",
    en: "Successful payment",
  },
  reserving: {
    es: "Reservando",
    en: "Reserving",
  },
  startingStripe: {
    es: "Iniciando Stripe",
    en: "Starting Stripe",
  },
  all: {
    es: "Todos",
    en: "All",
  },
  weekType: {
    es: "Tipo de semana",
    en: "Week Type",
  },
  loadingDescription: {
    es: "Cargando descripción",
    en: "Loading description",
  },
  loadingResortName: {
    es: "Cargando nombre",
    en: "Loading name",
  },
  resortSummary: {
    es: "Resumen del Resort",
    en: "Resort Summary",
  },
  enterTravelerData: {
    es: "Ingresa los datos del viajero",
    en: `Enter the traveler's data`,
  },
  primaryContact: {
    es: "Contacto Primario",
    en: "Primary Contact",
  },
  mandatory: {
    es: "Obligatorio",
    en: "Mandatory",
  },
  termsAndConditions: {
    es: "Términos y Condiciones",
    en: "Terms and Conditions",
  },
  msgTermsAndConditions: {
    es: "He leído y acepto las condiciones de uso, las observaciones de la reserva y los gastos de cancelación.",
    en: "I have read and I accept the conditions of use, the booking remarks and cancellation costs.",
  },
  name: {
    es: "Nombre",
    en: "Name",
  },
  next: {
    es: "Continuar",
    en: "Next",
  },
  back: {
    es: "Volver",
    en: "Go back",
  },
  lastname: {
    es: "Apellido",
    en: "Lastname",
  },
  email: {
    es: "Correo Electrónico",
    en: "Email",
  },
  cellphone: {
    es: "Celular",
    en: "Cellphone",
  },
  phone: {
    es: "Teléfono",
    en: "Phone",
  },
  countryCode: {
    es: "Código País",
    en: "Country Code",
  },
  address: {
    es: "Dirección",
    en: "Address",
  },
  zip: {
    es: "Código Postal",
    en: "ZIP",
  },
  book: {
    es: "Reservar",
    en: "Book",
  },
  finalPrice: {
    es: "Precio Final",
    en: "Final Price",
  },
  msgConfirmPayment: {
    es: "Tienes 30 minutos a partir de ahora para completar el pago. Si necesita más tiempo por favor regrese y confirme el viaje nuevamente antes de pagar. *Precios y disponibilidad no están garantizados.",
    en: "You have 30 minutes from now to complete payment. If you need more time please go back and confirm the trip again before paying. *Prices and availability are not guaranteed.",
  },
  warning: {
    es: "Alerta",
    en: "Warning",
  },
  paymentOptions: {
    es: "Opciones de pago",
    en: "Payment options",
  },
  paymentSystem: {
    es: "Sistema de pago",
    en: "Payment system",
  },
  orderBy: {
    es: "Ordenar por",
    en: "Order by",
  },
  priceLowest: {
    es: "Precio (El más bajo primero)",
    en: "Price (lowest first)",
  },
  priceHighest: {
    es: "Precio (El más alto primero)",
    en: "Price (highest first)",
  },
  capacity: {
    es: "Tipo de unidad",
    en: "Unit Type",
  },
  kitchen: {
    es: "Cocina",
    en: "Kitchen",
  },
  occupancy: {
    es: "Max. Ocupación",
    en: "Max. Occupancy",
  },
  resortSelect: {
    es: "Nombre del Resort",
    en: "Resort Name",
  },
  resetFilters: {
    es: "Restablecer filtros",
    en: "Reset Filters",
  },
  recoverPassword: {
    es: "Recuperar contraseña",
    en: "Recover Password",
  },
  recoverPasswordMsg: {
    es: "Introduce tu correo electrónico o LoginID",
    en: "Enter your Email or LoginID",
  },
  recoverPasswordMsg2: {
    es: "Recibirás un correo electrónico con instrucciones para restablecer tu contraseña.",
    en: "You will receive an email with instructions to reset your password",
  },
  emailSend: {
    es: "Correo electrónico enviado",
    en: "Email send",
  },
  resetPassword: {
    es: "Restablecer contraseña",
    en: "Reset password",
  },
  newPassword: {
    es: "Nueva contraseña",
    en: "New password",
  },
  confirmPassword: {
    es: "Confirmar contraseña",
    en: "Confirm password",
  },
  notMatchPassword: {
    es: "Las contraseñas no coinciden",
    en: "Passwords do not match",
  },
  resetPasswordOk: {
    es: "Cambio de contraseña exitoso",
    en: "Successful password change",
  },
  logout: {
    es: "Cerrar sesión",
    en: "Logout",
  },
  maxOccupancy: {
    es: "Max. Ocupación",
    en: "Max. Occupancy",
  },
  cruises: {
    es: "Cruceros",
    en: "Cruises",
  },
  destination: {
    es: "Destino",
    en: "Destination",
  },
  allDestinations: {
    es: "Todos los Destino",
    en: "All Destinations",
  },
  depPort: {
    es: "Puerto de salida",
    en: "Dep Port",
  },
  allPorts: {
    es: "Todos los Puertos",
    en: "All Ports",
  },
  cruiseLine: {
    es: "Línea de Cruceros",
    en: "Cruise Line",
  },
  allCruiseLines: {
    es: "todas las líneas de cruceros",
    en: "All cruise lines",
  },
  ship: {
    es: "Barco",
    en: "Ship",
  },
  allShips: {
    es: "Todos los barcos",
    en: "All ships",
  },
  month: {
    es: "Mes",
    en: "Month",
  },
  allMonths: {
    es: "All Months",
    en: "Todos los Meses",
  },
  numberOfDay: {
    es: "Número de Días",
    en: "Number of Days",
  },
  allLengths: {
    es: "Todas las Longitudes",
    en: "All Lengths",
  },
  infoImportan__msg1: {
    es: "Todas las reservaciones no son cancelables.",
    en: "All reservations are final.",
  },
  close: {
    es: "Cerrar",
    en: "Close",
  },
  cookiesPolicy__msg1: {
    es: `Utilizamos cookies propias y de terceros (Google y
        Facebook) para analizar el uso de nuestro sitio web, mostrarle
        Anuncios personalizados según tus hábitos de navegación y mejorar.
        rendimiento de navegación.`,
    en: `We use first-party and third-party cookies (Google and
        Facebook) to analyze the usage of our website, show you
        tailored ads according your browsing habits and improve
        browsing performance.`,
  },
  accept: {
    es: "Aceptar",
    en: "Accept",
  },
  completeSearch: {
    es: "Búsqueda completa",
    en: "Complete search",
  },
  filters: {
    es: "Filtros",
    en: "Filters",
  },
  allFieldsRequired: {
    es: "Todos los campos son requeridos",
    en: "All fields are required",
  },
  fieldStateRequired: {
    es: "Para el país {{country}} es necesario seleccionar su estado",
    en: "For the country {{country}} you need to select your state",
  },
  fieldOnlyLettersAndSpaces: {
    es: "El campo {{field}} solo admite letras y espacios",
    en: "The field {{field}} only accepts letters and spaces",
  },
  acceptTerms: {
    es: "Aceptar los términos y condiciones es requerido",
    en: "Accepting the terms and conditions is required",
  },
  emailInvalid: {
    es: "El formato Email no es valido",
    en: "Email format is not valid",
  },
  passwordRecovery: {
    es: "Si encontramos una cuenta que coincida en nuestros registros, se enviará un correo electrónico con las instrucciones para restablecer tu contraseña a tu dirección de correo electrónico registrada.",
    en: "If we find a matching account in our records, an email with instructions on how to reset your password will be sent to your registered email address.",
  },
  checkInOrderHighest: {
    es: "CheckIn (El más alto primero)",
    en: "Check In (highest first)",
  },
  checkInOrderLowest: {
    es: "CheckIn (El más bajo primero)",
    en: "Check In (lowest first)",
  },
  update: {
    es: "Actualizar",
    en: "Update",
  },
  security: {
    es: "Seguridad",
    en: "Security",
  },
  myData: {
    es: "Mis datos",
    en: "My Data",
  },
  updatePassword: {
    es: "Actualizar contraseña",
    en: "Update Password",
  },
  subscription: {
    es: "Suscripción",
    en: "Subscription",
  },
  birthdate: {
    es: "Fecha de nacimiento",
    en: "Birthdate",
  },
  contract: {
    es: "Contrato",
    en: "Contract",
  },
  nacionality: {
    es: "Nacionalidad",
    en: "Nationality",
  },
  maritalStatus: {
    es: "Estado civil",
    en: "Marital Status",
  },
  selectOption: {
    es: "Seleccione una opción",
    en: "Select an option",
  },
  owner: {
    es: "Titular",
    en: "Owner",
  },
  coOwner: {
    es: "Co-titular",
    en: "Co-owner",
  },
  payments: {
    es: "Pagos",
    en: "Payments",
  },
  contactUs: {
    es: "Contáctanos",
    en: "Contact us",
  },
  loginError__msg1: {
    es: "Tu contraseña es incorrecta o esta cuenta no existe. Por favor restablece tu contraseña o contáctanos.",
    en: "Your password is incorrect or this account doesn’t exist. Please reset your password or contact us.",
  },
  changePassword: {
    es: "Cambiar contraseña",
    en: "Change password",
  },
  changePassword__msg1: {
    es: "Por favor, introduce tu nueva contraseña",
    en: "Please, introduce your new password",
  },
  changePassword__msg2: {
    es: "La contraseña debe tener al menos 8 caracteres, 1 minúscula, 1 mayúscula, 1 número, 1 carácter especial y sin espacios en blanco.",
    en: "Password must have at least 8 characters, 1 lowercase, 1 uppercase, 1 number, 1 special character and without blank spaces.",
  },
  changePassword__msg3: {
    es: "La contraseña no cumple con los requisitos de seguridad, intente nuevamente.",
    en: "Password does not meet the security requirements, try again.",
  },
  changePassword__msg4: {
    es: "Hubo un error al cambiar la contraseña, intente nuevamente o contacte con soporte.",
    en: "There was an error changing the password, try again or contact support.",
  },
  changePassword__msg5: {
    es: "El token para cambiar la contraseña expiró, reenvie el correo electrónico para que puedas cambiar tu contraseña.",
    en: "The token to change the password has expired, please send the email again to change your password.",
  },
  termsAndConditions__msg1: {
    es: "He leído y acepto los términos y condiciones",
    en: "I have read and accept the terms and conditions",
  },
  termsAndConditions__msg2: {
    es: "No acepto los términos y condiciones",
    en: "I do not accept the terms and conditions",
  },
  iAccept: {
    es: "Acepto",
    en: "I accept",
  },
  cancel: {
    es: "Cancelar",
    en: "Cancel",
  },
  resortAll: {
    es: "Todos los Resorts",
    en: "All Resorts",
  },
  allCities: {
    es: 'Todas las ciudades',
    en: 'All cities'
  },
  weeksIn: {
    es: 'Semanas en',
    en: 'Weeks in'
  },
  internalError: {
    es: 'Error interno, intente nuevamente o contactenos.',
    en: 'Internal error, try again or contact us.'
  }
};
